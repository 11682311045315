/**
 * Dialog Utility functions
 * @author David Kirkland <david.kirkland@nec.com.au>
 */
import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Draggable from 'react-draggable';
import { ScrollView, Text } from '@aws-amplify/ui-react';

/**
 * Create a simple alert dialog
 * @param {{}} props 
 * @returns dialog to be rendered
 */
function AlertDialog(props) {
    return (
        <Dialog
            open={props.content !== null}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {props.title &&
                <DialogTitle id="alert-dialog-title">
                    {props.title}
                </DialogTitle>
            }
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ whiteSpace: 'pre-line' }}>
                    {props.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
                <Button variant="outlined" style={{ minWidth: '6rem' }} onClick={props.onCancel}>Cancel</Button>
                <Button variant="contained" style={{ minWidth: '6rem' }} onClick={props.onOk}> OK</Button>
            </DialogActions>
        </Dialog>
    );
}

/**
 * Create a dialog for editing bus stop details
 * @param {{}} props 
 * @returns the dialog to be rendered
 */
function EditStopDialog(props) {
    const fieldWidth = 225;
    const stopNameRef = useRef('');
    const stopIdRef = useRef('');
    const stopSequenceRef = useRef('');
    const draggableRef = useRef('');

    const handleOk = () => {
        if (stopNameRef.current.reportValidity() && stopIdRef.current.reportValidity() && stopSequenceRef.current.reportValidity()) {
            let result = props.values;
            result.stopName = stopNameRef.current.value;
            result.stopId = stopIdRef.current.value;
            result.sequenceNo = stopSequenceRef.current.value;
            props.onOk(result);
        } else {
            console.warn("Stop details validation error");
        }
    }

    const handleCancel = () => {
        let result = props.values;
        props.onCancel(result);
    }

    const paperComponent = (props) => {
        return (
            <Draggable nodeRef={draggableRef} cancel={'[class*="MuiDialogContent-root"]'}>
                <div ref={draggableRef}>
                    <Paper {...props} />
                </div>
            </Draggable>
        );
    }

    return (
        <Dialog
            open={Object.keys(props.values).length !== 0}
            aria-labelledby="edit-stop-dialog-title"
            PaperComponent={paperComponent}
            onClose={handleCancel}
        >
            <DialogTitle style={{ cursor: 'move' }} id="edit-stop-dialog-title">
                {(props.edit ? "Edit " : "") + "Stop Details"}
            </DialogTitle>
            <DialogContent>
                <div>
                    <TextField
                        required={props.edit}
                        style={{ width: (fieldWidth * 2) }}
                        autoFocus
                        margin="dense"
                        id="stop-name"
                        label="Stop Name"
                        inputProps={{ readOnly: !props.edit }}
                        defaultValue={props.values.stopName}
                        inputRef={stopNameRef}
                    />
                </div>
                <div>
                    <TextField
                        required={props.edit}
                        style={{ width: fieldWidth }}
                        margin="dense"
                        id="stop-id"
                        label="Stop ID"
                        inputProps={{ readOnly: !props.edit }}
                        defaultValue={props.values.stopId}
                        inputRef={stopIdRef}
                    />
                    <TextField
                        style={{ width: fieldWidth }}
                        margin="dense"
                        id="stop-sequence-number"
                        label="Sequence Number"
                        type="number"
                        inputProps={{ min: 0, readOnly: true }}
                        defaultValue={props.values.sequenceNo}
                        inputRef={stopSequenceRef}
                    />
                </div>
                <div>
                    <TextField
                        style={{ width: fieldWidth }}
                        margin="dense"
                        id="stop-lat"
                        label="Latitude"
                        type="number"
                        InputProps={{ min: -90, max: 90, readOnly: true }}
                        defaultValue={props.values.lat}
                    />
                    <TextField
                        style={{ width: fieldWidth }}
                        margin="dense"
                        id="stop-lng"
                        label="Longitude"
                        type="number"
                        InputProps={{ min: -180, max: 180, readOnly: true }}
                        defaultValue={props.values.lng}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
                <Button variant="outlined" style={{ minWidth: '6rem' }} onClick={handleCancel}>{props.edit ? "Cancel" : "Close"}</Button>
                {props.edit && <Button variant="contained" style={{ minWidth: '6rem' }} onClick={handleOk}>OK</Button>}
            </DialogActions>
        </Dialog>
    );
}

/**
 * Create a dialog for editing route details
 * @param {{}} props 
 * @returns the dialog to be rendered
 */
function EditRouteDetailsDialog(props) {
    const fieldWidth = 225;
    const routeNameRef = useRef('');
    const routeDestinationRef = useRef('');
    const routeVariantRef = useRef('');
    const draggableRef = useRef('');

    const handleOk = () => {
        if (routeNameRef.current.reportValidity() && routeDestinationRef.current.reportValidity() && routeVariantRef.current.reportValidity()) {
            let result = {
                route: routeNameRef.current.value,
                destination: routeDestinationRef.current.value,
                variant: routeVariantRef.current.value
            };
            props.onOk(result);
        } else {
            console.warn("Route details validation error");
        }
    }

    const handleCancel = () => {
        let result = props.values;
        props.onCancel(result);
    }

    const paperComponent = (props) => {
        return (
            <Draggable nodeRef={draggableRef} cancel={'[class*="MuiDialogContent-root"]'}>
                <div ref={draggableRef}>
                    <Paper {...props} />
                </div>
            </Draggable>
        );
    }

    return (
        <Dialog
            open={Object.keys(props.values).length !== 0}
            aria-labelledby="edit-route-dialog-title"
            PaperComponent={paperComponent}
            onClose={handleCancel}
        >
            <DialogTitle style={{ cursor: 'move' }} id="edit-route-dialog-title">
                {props.title || "Edit Route Details"}
            </DialogTitle>
            <DialogContent>
                <div>
                    <TextField
                        required={props.edit}
                        style={{ width: (fieldWidth) }}
                        autoFocus
                        margin="dense"
                        id="route"
                        label="Route"
                        inputProps={{ readOnly: !props.edit }}
                        defaultValue={props.values.route}
                        inputRef={routeNameRef}
                    />
                    <TextField
                        style={{ width: fieldWidth }}
                        margin="dense"
                        id="variant"
                        label="Variant"
                        inputProps={{ readOnly: !props.edit }}
                        defaultValue={props.values.variant}
                        inputRef={routeVariantRef}
                    />
                </div>
                <div>
                    <TextField
                        required={props.edit}
                        style={{ width: fieldWidth * 2 }}
                        margin="dense"
                        id="destination"
                        label="Destination"
                        inputProps={{ readOnly: !props.edit }}
                        defaultValue={props.values.destination}
                        inputRef={routeDestinationRef}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
                <Button variant="outlined" style={{ minWidth: '6rem' }} onClick={handleCancel}>{props.edit ? "Cancel" : "Close"}</Button>
                {props.edit && <Button variant="contained" style={{ minWidth: '6rem' }} onClick={handleOk}>OK</Button>}
            </DialogActions>
        </Dialog>
    );
}

/**
 * Create a dialog to display a list of issues found with the route
 * @param {{}} props 
 * @returns dialog to be rendered
 */
function RouteIssueDialog(props) {
    const showDialog = props.issueList !== null;

    return (
        <Dialog
            open={showDialog}
            aria-labelledby="route-issue-list-dialog-title"
            fullWidth={true}
            maxWidth={"sm"}
        >
            <DialogTitle id="route-issue-list-dialog-title">
                {/* Warning */}
                <b>{showDialog && ("Route is not valid : " + props.issueList.length + " issue" + (props.issueList.length > 1 ? "s" : "") + " found")}</b>
            </DialogTitle>
            <DialogContent>
                {/* <DialogContentText >
                    <b>{showDialog && ("Route is not valid - " + props.issueList.length + " issue" + (props.issueList.length > 1 ? "s" : "") + " found:")}</b>
                </DialogContentText> */}
                    <ScrollView height="300px" backgroundColor="orange.20">
                    {showDialog && props.issueList.map(issue => <Text key={issue}>{issue}</Text>)}
                </ScrollView>
                <br />
                {props.showCancelButton && <DialogContentText align="center" >Continue anyway?</DialogContentText>}
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
                {props.showCancelButton && <Button variant="outlined" style={{ minWidth: '6rem' }} onClick={props.onCancel}>Cancel</Button>}
                <Button variant="contained" style={{ minWidth: '6rem' }} onClick={props.onOk}>OK</Button>
            </DialogActions>
        </Dialog>
    );
}

/**
 * Create a dialog for switching customers
 * @param {{}} props 
 * @returns dialog to be rendered
 */
function SwitchCustomerDialog(props) {
    const [selectedCustomer, setSelectedCustomer] = useState("");

    const handleOk = () => {
        props.onOk(selectedCustomer);
        setSelectedCustomer("");
    }

    const handleCancel = () => {
        props.onCancel();
        setSelectedCustomer("");
    }

    return (
        <Dialog
            open={props.show}
            aria-labelledby="select-customer-dialog-title"
            aria-describedby="select-customer-dialog-description"
        >
            <DialogTitle id="select-customer-dialog-title">
                Switch Customer
            </DialogTitle>
            <DialogActions style={{ justifyContent: "center" }}>
                <FormControl sx={{ margin: 1, minWidth: "15rem" }} size="small">
                    <InputLabel id="select-action-label">Customer</InputLabel>
                    <Select
                        labelId="select-action-label"
                        id="select-action"
                        label="Customer"
                        value={selectedCustomer}
                        autoWidth
                        onChange={(event) => setSelectedCustomer(event.target.value)}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {props.customerList && props.customerList.map((customer, index) => <MenuItem key={index} value={customer.name}>{customer.displayName}</MenuItem>)}
                    </Select>
                </FormControl>
                <Button variant="outlined" style={{ minWidth: '6rem' }} onClick={handleCancel} autoFocus>Cancel</Button>
                <Button variant="contained" style={{ minWidth: '6rem' }} disabled={!selectedCustomer} onClick={handleOk}> OK</Button>
            </DialogActions>
        </Dialog>
    );
}

/**
 * Create a dialog for changing the user's password
 * @param {{}} props 
 * @returns dialog to be rendered
 */
function ChangePasswordDialog(props) {
    const [isValid, setIsValid] = useState(false),
        [newPasswordError, setNewPasswordError] = useState(false);
    const currentPasswordRef = useRef(''),
        newPasswordRef = useRef(''),
        confirmPasswordRef = useRef('');


    const handleOk = () => {
        if (isValid) {
            props.onOk(currentPasswordRef.current.value, newPasswordRef.current.value);
        } else {
            console.warn("Password input validation error");
        }
    }

    const handleCancel = () => {
        props.onCancel();
    }

    const validateInputs = () => {
        let newPasswordError = (newPasswordRef.current.value.length > 0 && confirmPasswordRef.current.value.length > 0 &&
            (newPasswordRef.current.value !== confirmPasswordRef.current.value));
        setNewPasswordError(newPasswordError);
        setIsValid(currentPasswordRef.current.value && newPasswordRef.current.value &&
            confirmPasswordRef.current.value && !newPasswordError);
    }

    return (
        <Dialog
            open={props.show}
            aria-labelledby="change-password-dialog-title"
            aria-describedby="change-password-dialog-description"
        >
            <DialogTitle id="change-password-dialog-title">
                Change Password
            </DialogTitle>
            <DialogContent>
                <div>

                    <TextField
                        required
                        margin="dense"
                        id="old-password"
                        label="Old Password"
                        type="password"
                        inputRef={currentPasswordRef}
                        onChange={validateInputs}
                    />
                </div>
                <div>
                    <TextField
                        required
                        margin="dense"
                        id="new-password"
                        label="New Password"
                        type="password"
                        inputRef={newPasswordRef}
                        onChange={validateInputs}
                    />
                </div>
                <div>
                    <TextField
                        required
                        error={newPasswordError}
                        helperText={newPasswordError ? "New passwords do not match" : " "}
                        margin="dense"
                        id="confirm-password"
                        label="Confirm Password"
                        type="password"
                        inputRef={confirmPasswordRef}
                        onChange={validateInputs}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
                <Button variant="outlined" style={{ minWidth: '6rem' }} onClick={handleCancel} autoFocus>Cancel</Button>
                <Button variant="contained" style={{ minWidth: '6rem' }} disabled={!isValid} onClick={handleOk}> OK</Button>
            </DialogActions>
        </Dialog>
    );
}

export {
    AlertDialog, EditStopDialog, EditRouteDetailsDialog, RouteIssueDialog, SwitchCustomerDialog, ChangePasswordDialog
};
