/**
 * @module ImportRoutes
 * @author David Kirkland <david.kirkland@nec.com.au>
 */
import React from 'react';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import ImportIcon from '@mui/icons-material/UploadOutlined';
import SelectAllIcon from '@mui/icons-material/SelectAllOutlined';
import DeselectIcon from '@mui/icons-material/DeselectOutlined';
import { Flex, Divider } from '@aws-amplify/ui-react';
import {
    getAwsRouteList, saveRouteToAws, routeExistsInAwsFolder, copyAwsRoute, storageFolderDraft, storageFolderReview, storageFolderReference
} from './AwsFunctions';
import { getTmsRouteList, getTmsRouteDetails, convertTmsRoute, processTmsDataBundle } from './TmsUtils';
import { importLocationLog } from './ImportUtils';
import { AlertDialog, EditRouteDetailsDialog } from './DialogUtils';
import { RouteList } from './RouteDialogs';
import { ImportProgress, ImportConfirmation, ImportSummary } from './ImportDialogs';
import {
    severityError, severityWarning, severitySuccess
} from './App';
import { getFilenameForGeoJSON, routeToGeoJSON } from './GeoJsonUtils';
import { selectFile, readFile } from './FileUtils';
import { createRoutePolyline, createStopMarkers, createFilteredRoutePolyline, prepareRouteForExport } from './MapUtils';
import { Logger } from 'aws-amplify';

const logger = new Logger('ImportRoutes', 'INFO');

// route file actions
const importSourceTmsApi = 'tmsApi',
    importSourceTmsDataBundle = 'tmsDataBundle',
    importSourceLocationLog = 'locationLog';

/**
 * Class to import routes into AWS
 */
export default class ImportRoutes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            routeList: null,
            selectedItems: [],
            selectedSource: "",
            alertDialogContent: null,
            alertDialogTitle: null,
            importProgress: null,
            routeBeingImported: null,
            showImportConfirmation: false,
            showImportSummary: false,
            editRouteValues: {}
        }

        // alert dialog callbacks
        this.alertDialogCallbackOK = null;
        this.alertDialogCallbackCancel = null;

        // the list of routes currently in AWS
        this.awsRouteList = null;

        this.routesToImport = [];
        this.importOk = [];
        this.importFailed = [];
        this.importSkipped = [];
        this.overwriteExistingRoutes = false;
        this.importSource = null;
        this.singleRouteToImport = null;
        this.autoFilterSetting = null;
        this.importDestination = storageFolderDraft;
    }

    componentDidMount() {
        if (!this.alreadyMounted) {
            this.alreadyMounted = true;
            // retrieve the list of routes from AWS S3
            this.updateAwsRouteList(true);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.activeCustomer !== prevProps.activeCustomer) {
            this.updateAwsRouteList(true);
        }
    }

    /**
     * Retrieve the latest route list from AWS
     */
    updateAwsRouteList(alertOnError = false) {
        const showCircularProgressBar = this.props.showCircularProgressBar;

        showCircularProgressBar(true);
        getAwsRouteList((routeList) => {
            showCircularProgressBar(false);
            this.awsRouteList = routeList;
            logger.debug("AWS routes: ", routeList);
            if (routeList === null && alertOnError) {
                this.props.onAlert("Unable to load current route list", severityError, 5000, true);
            }
        });
    }

    /**
   * Show the alert dialog
   * @param {boolean} show true to show the dialog, false to hide it
   * @param {string} title the title to display in the dialog 
   * @param {string} content the text to display in the dialog
   * @param {function} onOk callback function for when OK is clicked
   * @param {function} onCancel callback function for when cancel is clicked
   */
    showAlertDialog(show, title, content, onOk, onCancel) {
        if (show) {
            this.alertDialogCallbackOK = onOk;
            this.alertDialogCallbackCancel = onCancel;
            this.setState({ alertDialogContent: content, alertDialogTitle: title });
        } else {
            this.setState({ alertDialogContent: null, alertDialogTitle: null });
        }
    }

    /**
     * Import a route
     * @param {*} route the route to import
     */
    importRoute(route) {
        if (route && route.details) {
            let fileName = getFilenameForGeoJSON(route.details);

            // Check before overwriting existing routes
            if (!this.overwriteExistingRoutes &&
                (routeExistsInAwsFolder(fileName, this.importDestination, this.awsRouteList) ||
                    routeExistsInAwsFolder(fileName, storageFolderReference, this.awsRouteList))) {
                logger.info("Skipping import of " + fileName + " (route already exists)");
                this.importNextRouteComplete(false, true);
            } else {
                // convert route to GeoJSON
                let geoJson = routeToGeoJSON(route),
                    filteredGeoJson = null;

                if (this.autoFilterSetting === null) {
                    logger.info("Importing " + fileName);
                } else {
                    logger.info("Filtering and Importing " + fileName);
                    let { geometry, stops, details } = route;
                    let polylineResult = createRoutePolyline(geometry);
                    let stopMarkers = createStopMarkers(stops);
                    let result = createFilteredRoutePolyline(polylineResult.verticeGroup.getObjects(), stopMarkers.getObjects(), this.autoFilterSetting);
                    if (result) {
                        // get the new route details (note: we reuse the stop markers from before)
                        let filteredRoute = prepareRouteForExport(result.polyline, result.verticeGroup, stopMarkers);
                        filteredRoute.details = details;
                        filteredGeoJson = routeToGeoJSON(filteredRoute);
                        logger.debug("Created filtered route with " + result.verticeGroup.getObjects().length + " waypoints");
                    } else {
                        logger.warn("Unable to create filtered route");
                        this.importNextRouteComplete(false, false);
                        return;
                    }
                }
                // Create a file blob for export
                const blob = new Blob([this.autoFilterSetting === null ? geoJson : filteredGeoJson], { type: "text/plain" });

                // Save the file to AWS (note route details are the same regardless of which geoJson was used to generate the blob)
                saveRouteToAws(route.details, this.importDestination + fileName, blob, null, (errorMessage) => {
                    if (errorMessage) {
                        this.importNextRouteComplete(false, false);
                    } else {
                        if (this.autoFilterSetting === null) {
                            // Make a copy in the reference folder as well
                            copyAwsRoute(this.importDestination + fileName, storageFolderReference + fileName, (errorMessage) => {
                                this.importNextRouteComplete(errorMessage === null, false);
                            });
                        } else {
                            // Save the original file to the reference folder
                            const referenceBlob = new Blob([geoJson], { type: "text/plain" });
                            saveRouteToAws(route.details, storageFolderReference + fileName, referenceBlob, null, (errorMessage) => {
                                this.importNextRouteComplete(errorMessage === null, false);
                            });
                        }
                    }
                });
            }
        } else {
            // error
            this.importNextRouteComplete(false, false);
        }
    }

    /**
     * Import the next route in the import list
     */
    importNextRoute() {
        const { routeList } = this.state;
        if (this.importSource === importSourceLocationLog && this.singleRouteToImport !== null) {
            let routeCode = getFilenameForGeoJSON(this.singleRouteToImport.details).split('.')[0];
            this.setState({ routeBeingImported: routeCode }, () => {
                // wait for the state to be updated, then continue
                this.importRoute(this.singleRouteToImport);
            });
        } else if (this.routesToImport.length > 0) {
            let routeKey = this.routesToImport.pop();
            let routeToImport = null;
            for (const r of routeList) {
                if (r.key === routeKey) {
                    routeToImport = r;
                    break;
                }
            }

            if (routeToImport !== null) {
                this.setState({ routeBeingImported: routeToImport.routeCode }, () => {
                    // wait for the state to be updated, then continue
                    switch (this.importSource) {
                        case importSourceTmsApi:
                            // retrieve the route details from TMS
                            getTmsRouteDetails(this.props.activeCustomer.name, routeKey, (ok, result) => {
                                if (!ok) {
                                    logger.error("Error: " + result);
                                    // get the next route
                                    this.importNextRouteComplete(false, false);
                                } else {
                                    // convert the retrieved TMS route to the required format
                                    let route = convertTmsRoute(result);
                                    this.importRoute(route);
                                }
                            });
                            break;
                        case importSourceTmsDataBundle:
                            // routeToImport already contains the route details in TMS format - convert it now
                            let route = convertTmsRoute(routeToImport);
                            this.importRoute(route);
                            break;
                        default:
                            break;
                    }

                });
            } else {
                logger.error("Unexpected error");
                // get the next route
                this.importNextRouteComplete(false, false);

            }

        } else {
            this.setState({ importProgress: null, routeBeingImported: null, selectedItems: [] });
        }
    }

    /**
     * Handle completion of importing a route and move on to the next route (if required)
     * @param {*} ok current route being imported completed successfully
     * @param {*} skipped current route being imported was skipped
     */
    importNextRouteComplete(ok, skipped) {
        const onAlert = this.props.onAlert,
            { selectedItems, routeBeingImported } = this.state;
        if (ok) {
            this.importOk.push(routeBeingImported);
        } else if (skipped) {
            this.importSkipped.push(routeBeingImported);
        } else {
            logger.error("Route import failed for " + routeBeingImported);
            this.importFailed.push(routeBeingImported);
        }

        // calculate percentage completion
        let percentComplete = Math.round((selectedItems.length - this.routesToImport.length) * 100 / selectedItems.length);
        if (this.singleRouteToImport !== null) {
            percentComplete = 100;
            this.routesToImport = [];
        }
        this.setState({ importProgress: percentComplete });
        if (percentComplete === 100) {
            // short delay here so the progress indicator displays 100% before being removed
            setTimeout(() => {
                this.setState({ importProgress: null, routeBeingImported: null, selectedItems: [], showImportSummary: true });
                logger.info("Import completed - OK:" + this.importOk.length + ", FAIL:" + this.importFailed.length + ", SKIP:" + this.importSkipped.length);
                if (this.importOk.length > 0 && this.importFailed.length === 0 && this.importSkipped.length === 0) {
                    onAlert("Successfully imported " + this.importOk.length + " route" + (this.importOk.length > 1 ? "s" : ""), severitySuccess, 3000, true);
                }
                // refresh the list of routes from AWS after we made some changes
                this.updateAwsRouteList(false);
            }, 1000);
        } else {
            // get the next route to import
            this.importNextRoute();
        }
    }

    /**
     * Load the list of routes that are available for import
     * @param {string} source the import source to use
     * @param {string} filterRouteName optional route name to filter on (inclusion filter)
     * @param {string} filterRouteDestination optional route destination to filter on (exclusion filter)
     */
    handleLoadRoutes(source, filterRouteName, filterRouteDestination) {
        const onAlert = this.props.onAlert,
            showCircularProgressBar = this.props.showCircularProgressBar;

        // clear the current route list
        this.setState({ routeList: null, selectedItems: [] })
        this.importSource = source;

        switch (source) {
            case importSourceTmsApi:
                // Import using the TMS REST API
                logger.info("Retrieve route list from TMS - filter: " + filterRouteName + " (" + filterRouteDestination + ")");
                showCircularProgressBar(true);
                // retrieve the list of available routes from TMS
                getTmsRouteList(this.props.activeCustomer.name, filterRouteName, filterRouteDestination, (ok, result) => {
                    showCircularProgressBar(false);
                    if (!ok) {
                        logger.error("Error retrieving route list from TMS: " + result);
                        this.setState({ routeList: null })
                        if (result === "Transformation too large") {
                            result = "too many results.  Try filtering by route number."
                        }
                        onAlert("Unable to load route list from TMS - " + result, severityError, 5000, true);
                    } else {
                        let routes = result.routeList,
                            numRoutes = result.routeList.length;
                        logger.info("Got list of " + numRoutes + " routes from TMS");
                        if (numRoutes > 0) {
                            onAlert(numRoutes + " route" + (numRoutes > 1 ? "s" : "") + " available for import from TMS", severitySuccess, 5000, true);
                            routes.forEach((route) => {
                                // save the TMS route ID as the key
                                route.key = route.id;
                                // set other values needed by the RouteList component
                                route.route = route.routeName;
                                route.destination = route.routeDestination;
                                route.variant = route.routeVariant;
                            });
                        } else {
                            onAlert("No routes available for import from TMS" + (filterRouteName ? (" matching route: " + filterRouteName) : ""), severityWarning, 5000, true);
                        }
                        logger.debug("TMS routes: ", routes);
                        this.setState({ routeList: routes })
                    }
                });
                break;
            case importSourceTmsDataBundle:
                // Import from a TMS data bundle file
                // Upload a file from the local machine
                selectFile(".json", false).then((file) => {
                    readFile(file, (content) => {
                        let bundle = JSON.parse(content);
                        let result = processTmsDataBundle(bundle);
                        if ('error' in result) {
                            logger.error(result.error);
                            onAlert(result.error, severityError, 5000, true);
                        } else if ('routeList' in result && result.routeList.length > 0) {
                            onAlert(result.routeList.length + " route" + (result.routeList.length > 1 ? "s" : "") +
                                " available for import", severitySuccess, 5000, true);
                            logger.debug("Available for import - Routes: " + result.routeList.length);
                            this.setState({ routeList: result.routeList });
                        } else {
                            onAlert("No routes available for import", severityWarning, 5000, true);
                        }
                    });
                });
                break;
            case importSourceLocationLog:
                // Import from a driver app location log file
                // Upload a file from the local machine
                selectFile(".txt", false).then((file) => {
                    readFile(file, (content) => {
                        let route = importLocationLog(file.name, content);
                        if (route.geometry.length >= 2) {
                            logger.info("Loaded route from " + file.name + " with " + route.geometry.length + " points");
                            logger.debug(route.geometry, route.stops);
                            this.setState({ editRouteValues: { name: "New Route" } });
                            this.singleRouteToImport = route;
                        } else {
                            onAlert("Invalid location log file", severityError, 5000, true);
                        }
                    });
                });
                break;
            default:
                break;
        }
    }

    /**
    * Set the details of the route
    * @param {Object} details the route data to be edited/saved
    */
    setRouteDetails(details) {
        let key = details.route + "_" + details.destination;
        this.singleRouteToImport.details = details;
        this.singleRouteToImport.details.variant = details.variant;
        this.routesToImport = [key]
        this.setState({ editRouteValues: {}, routeBeingImported: null, showImportConfirmation: true, selectedItems: [key] });
    }

    render() {
        const {
            routeList,
            selectedSource,
            selectedItems,
            alertDialogContent,
            alertDialogTitle,
            importProgress,
            routeBeingImported,
            showImportConfirmation,
            showImportSummary,
            editRouteValues,
            routeNameFilter,
            routeDestinationFilter
        } = this.state;

        return (
            <Container maxWidth={false} disableGutters sx={{ marginTop: 2 }}>
                <Flex justifyContent="left" style={{ padding: '1rem' }}>
                    <FormControl sx={{ margin: 0, minWidth: "15rem" }} size="small">
                        <InputLabel id="select-source-label">Import Source</InputLabel>
                        <Select
                            labelId="select-source-label"
                            id="select-source"
                            label="Import Source"
                            value={selectedSource}
                            autoWidth
                            onChange={(event) => { this.setState({ selectedSource: event.target.value }) }}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value={importSourceTmsApi}>TMS API</MenuItem>
                            <MenuItem value={importSourceTmsDataBundle}>TMS Data Bundle</MenuItem>
                            <MenuItem value={importSourceLocationLog}>Location Log File</MenuItem>
                        </Select>
                    </FormControl>
                    <Button disabled={!selectedSource} variant="contained" style={{ minWidth: '6rem' }} onClick={() => { this.handleLoadRoutes(selectedSource, routeNameFilter, routeDestinationFilter) }}>Load</Button>
                    {selectedSource === importSourceTmsApi && <TextField
                        size="small"
                        id="import-filter"
                        label="Filter by Route Number"
                        onChange={(event) => { this.setState({ routeNameFilter: event.target.value }) }}
                    />}
                    {selectedSource === importSourceTmsApi && <TextField
                        size="small"
                        id="import-exclusion-filter"
                        label="Destinations to exclude"
                        onChange={(event) => { this.setState({ routeDestinationFilter: event.target.value }) }}
                    />}
                </Flex>
                <Divider orientation="horizontal" style={{ padding: '0.25rem' }} />
                <br />
                <RouteList
                    listItems={routeList}
                    selectedItems={selectedItems}
                    showFolders={false}
                    onClick={(key) => {
                        let index = selectedItems.indexOf(key);
                        if (index !== -1) {
                            // remove the key
                            selectedItems.splice(index, 1);
                        } else {
                            // add the key
                            selectedItems.push(key);
                        }
                        this.setState({ selectedItems: selectedItems });
                        logger.debug(key, selectedItems);
                    }}
                    onDoubleClick={(key) => { }}
                    itemsPerPage={window.innerHeight < 500 ? 5 : (window.innerHeight - 290 - this.props.excludeHeight) / 50}
                />

                <Flex justifyContent="center" style={{ padding: '1rem' }}>
                    <Button
                        variant="outlined"
                        style={{ minWidth: '12rem' }}
                        disabled={routeList === null || routeList.length === 0 || routeList.length === selectedItems.length}
                        startIcon={<SelectAllIcon />}
                        onClick={() => {
                            let arr = [];
                            for (const route of routeList) {
                                arr.push(route.key);
                            }
                            this.setState({ selectedItems: arr });
                        }}>
                        Select All
                    </Button>
                    <Button
                        variant="outlined"
                        style={{ minWidth: '12rem' }}
                        disabled={routeList === null || selectedItems.length === 0}
                        startIcon={<DeselectIcon />}
                        onClick={() => { this.setState({ selectedItems: [] }) }}>
                        Clear Selection
                    </Button>
                    <Button
                        variant="contained"
                        style={{ minWidth: '12rem' }}
                        disabled={selectedItems.length === 0}
                        startIcon={<ImportIcon />}
                        onClick={() => {
                            this.routesToImport = [];
                            for (const item of selectedItems) {
                                this.routesToImport.push(item);
                            }
                            this.setState({ showImportConfirmation: true });
                        }}>
                        Import
                    </Button>
                </Flex>

                <ImportProgress
                    currentRoute={routeBeingImported}
                    percentComplete={importProgress}
                    onCancel={() => {
                        this.routesToImport = [];
                    }}
                />

                <ImportConfirmation
                    show={showImportConfirmation}
                    count={this.routesToImport.length}
                    onCancel={() => {
                        this.setState({ showImportConfirmation: false });
                        this.routesToImport = [];
                    }}
                    onOk={(overwrite, autoFilter, autoReview) => {
                        this.importOk = [];
                        this.importFailed = [];
                        this.importSkipped = [];
                        this.overwriteExistingRoutes = overwrite;
                        this.autoFilterSetting = autoFilter;
                        this.importDestination = autoReview ? storageFolderReview : storageFolderDraft;
                        this.setState({ importProgress: 0, routeBeingImported: null, showImportConfirmation: false });
                        logger.info("About to import " + this.routesToImport.length + " route" +
                            (this.routesToImport.length > 1 ? "s" : "") + " (overwrite = " + overwrite + ", auto-filter = " +
                            autoFilter + ", destination = " + this.importDestination + ")");
                        this.importNextRoute();
                    }}
                />

                <ImportSummary
                    show={showImportSummary}
                    imported={this.importOk}
                    skipped={this.importSkipped}
                    failed={this.importFailed}
                    onOk={() => { this.setState({ showImportSummary: false }) }}
                />

                <EditRouteDetailsDialog
                    title={"Imported Route Details"}
                    edit={true}
                    values={editRouteValues}
                    onOk={(details) => { this.setRouteDetails(details) }}
                    onCancel={(details) => { this.setState({ editRouteValues: {}, routeBeingImported: null }) }}
                />

                <AlertDialog
                    title={alertDialogTitle}
                    content={alertDialogContent}
                    onOk={this.alertDialogCallbackOK}
                    onCancel={this.alertDialogCallbackCancel}
                />
            </Container>
        );
    }
}
