/**
 * @module Map
 * @author David Kirkland <david.kirkland@nec.com.au>
 */
import React from 'react';
import H from "@here/maps-api-for-javascript";
import onResize from 'simple-element-resize-detector';
import {
  extractRouteFromGeoJSON, routeToGeoJSON, readGeoJSONFile, getFilenameForGeoJSON, geoJsonExtension
} from './GeoJsonUtils';
import {
  selectFile, exportFile
} from './FileUtils';
import {
  createRoutePolyline, createStopMarkers, zoomToObject, setDraggable, createCustomMapUiControl,
  initialisePreviousRouteValues, restorePreviousRouteValues, wasRouteModified, calculateRoute,
  createFilteredRoutePolyline, prepareRouteForExport, addRouteEventListeners, addMapEventListeners,
  deleteRouteStop, mapGroupNamePlannedRoute, validateRoute, updateStopSequenceNumbers, maxRouteWaypoints,
  createReferenceRoutePolyline
} from './MapUtils';
import {
  svgIconEdit, svgIconCheck,
  svgIconCancel, svgIconDiscard, svgIconNavigate,
  svgIconFilter, svgIconUndo, svgIconOverlay
} from './MapIcons';
import {
  AlertDialog, EditRouteDetailsDialog, EditStopDialog, RouteIssueDialog
} from './DialogUtils';
import {
  saveRouteToAws, retrieveRouteFromAws, getAwsRouteList, routeIsReadOnly, routeExistsInAws, storageFolderReference,
  storageFolderDraft, storageFolderReview, getRouteSourceFolder
} from './AwsFunctions';
import { SelectAwsRouteDialog } from './RouteDialogs';
import {
  menuActionSaveRoute, menuActionSaveAsRoute, menuActionOpenRoute, menuActionUploadRoute, menuActionDownloadRoute, menuActionNewRoute
} from './AppBar';
import {
  severityError, severityWarning, severityInfo, severitySuccess
} from './App';


export default class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertDialogContent: null,
      alertDialogTitle: null,
      routeSelectionDialogEnabled: false,
      editStopValues: { stopDetails: {}, edit: false },
      editRouteValues: { details: {}, edit: false },
      routeIssueList: null
    };
    // the reference to the container
    this.ref = React.createRef();
    // reference to the map
    this.map = null;
    // the online routing service
    this.routingService = null;
    // the current route object
    this.route = null;
    this.routeDetails = null;
    this.routeGroup = null;
    this.calculatedRoute = null;
    this.routeWasModified = false;
    this.routeExportPending = false;
    this.drawRouteIndex = null;
    this.readOnlyRoute = false;
    this.routeEditingEnabled = false;
    this.routeSourceFolder = null;
    // alert dialog callbacks
    this.alertDialogCallbackOK = null;
    this.alertDialogCallbackCancel = null;

    // a list of available routes (from AWS)
    this.routeList = null;

    // the reference route (for comparison with the edited route)
    this.referenceRoutePolyline = null;

    // Callback method bindings
    this.handleCalculateRouteButton = this.handleCalculateRouteButton.bind(this);
    this.handleEditRouteButton = this.handleEditRouteButton.bind(this);
    this.handleClearRouteButton = this.handleClearRouteButton.bind(this);
    this.handleUndoButton = this.handleUndoButton.bind(this);
    this.handleCancelChangesButton = this.handleCancelChangesButton.bind(this);
    this.handleSaveChangesButton = this.handleSaveChangesButton.bind(this);
    this.handleFilterButton = this.handleFilterButton.bind(this);
    this.handleRouteFileSelected = this.handleRouteFileSelected.bind(this);
    this.handleReferenceRouteLoaded = this.handleReferenceRouteLoaded.bind(this);
    this.handleToggleReferenceRouteOverlay = this.handleToggleReferenceRouteOverlay.bind(this);
    this.loadNewRoute = this.loadNewRoute.bind(this);
    this.handleRouteCalculationResult = this.handleRouteCalculationResult.bind(this);
    this.zoomToRoute = this.zoomToRoute.bind(this);
    this.clearMap = this.clearMap.bind(this);
    this.handleShowStopDetails = this.handleShowStopDetails.bind(this);
    this.handleDrawRoute = this.handleDrawRoute.bind(this);
    this.loadMap = this.loadMap.bind(this);
  }

  componentDidMount() {
    if (!this.alreadyMounted) {
      this.alreadyMounted = true;
      if (!this.map) {
        // Load the map
        this.loadMap(this.props.apiKey);
        // Check the details of the logged-in user
        this.checkUserDetails();
      }
    }
  }

  loadMap(apiKey) {
    if (!this.map) {
      if (!apiKey) {
        const onAlert = this.props.onAlert;
        onAlert("Unable to load maps - check API key", severityError, 0);
        return;
      }
      // instantiate a platform, default layers and a map
      const platform = new H.service.Platform({
        apikey: apiKey
      });
      const defaultLocation = new H.geo.Point(-37.8, 145.0);
      const defaultLayers = platform.createDefaultLayers();
      const padding = 50; // pixels
      this.map = new H.Map(
        this.ref.current,
        defaultLayers.vector.normal.map,
        //defaultLayers.raster.normal.map,
        {
          pixelRatio: window.devicePixelRatio || 1,
          center: defaultLocation,
          zoom: 10,
          padding: { top: padding, left: padding, bottom: padding, right: (padding * 2) }
        },
      );
      onResize(this.ref.current, () => {
        this.map.getViewPort().resize();
        // determine the map height required to fill the window
        this.mapHeight = window.innerHeight - this.props.excludeHeight;
      });

      // attach the map view changed event listener
      this.map.addEventListener('mapviewchange', (evt) => this.handleMapViewChange(evt));

      // add the interactive behaviour to the map
      this.behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));

      // Create the default UI components
      this.ui = H.ui.UI.createDefault(this.map, defaultLayers, 'en-US');

      // create a custom mapsettings control with 3 base map options and no traffic options
      // (refer to https://stackoverflow.com/questions/57136959/how-do-i-get-a-terrain-map-in-ui-controls-here-maps-v3-1)
      var ms = new H.ui.MapSettingsControl({
        baseLayers: [{
          label: 'normal', layer: defaultLayers.vector.normal.map
        }, {
          label: 'satellite', layer: defaultLayers.raster.satellite.map
        }, {
          label: 'terrain', layer: defaultLayers.raster.terrain.map
        }
        ],
        // layers: [{
        //   label: "layer.traffic", layer: defaultLayers.vector.normal.traffic
        // },
        // {
        //   label: "layer.incidents", layer: defaultLayers.vector.normal.trafficincidents
        // }
        // ]
      });
      // remove the default mapsettings control, add the custom control and then fix the alignment
      this.ui.removeControl('mapsettings');
      this.ui.addControl('mapsettings', ms);
      this.ui.getControl('mapsettings').setAlignment('bottom-right');
      this.ui.getControl('scalebar').setAlignment('bottom-right');
      this.ui.getControl('zoom').setAlignment('right-bottom');


      addMapEventListeners(this.map, this.ui, this.zoomToRoute, this.clearMap,
        this.handleDrawRoute,
        (edit) => {
          this.saveAfterEditRouteDetails = false;
          this.handleEditRouteDetails(true, edit, this.routeDetails)
        },
        () => {
          // export the calculated route shape (but not the stops)
          if (this.calculatedRoute) {
            let geoJson = routeToGeoJSON({ geometry: this.calculatedRoute.vertices, stops: [], details: this.routeDetails });
            let fileName = getFilenameForGeoJSON(this.routeDetails, this.fileName).replace(geoJsonExtension, " (calculated)" + geoJsonExtension);
            // Create a file blob for export
            const blob = new Blob([geoJson], { type: "text/plain" });
            console.log("Exporting calculated route geometry to " + fileName);
            exportFile(fileName, blob);
          }
        });

      // Add some custom controls
      createCustomMapUiControl(this.ui, 'route', svgIconNavigate, 'right-top', this.handleCalculateRouteButton, 'Calculate Route');
      createCustomMapUiControl(this.ui, 'edit', svgIconEdit, 'right-top', this.handleEditRouteButton, 'Edit Route');
      createCustomMapUiControl(this.ui, 'clear', svgIconDiscard, 'right-top', this.handleClearRouteButton, 'Clear Route');
      createCustomMapUiControl(this.ui, 'filter', svgIconFilter, 'right-top', this.handleFilterButton, 'Filter Route');
      createCustomMapUiControl(this.ui, 'undo', svgIconUndo, 'right-top', this.handleUndoButton, 'Revert to original route');
      createCustomMapUiControl(this.ui, 'check', svgIconCheck, 'right-top', this.handleSaveChangesButton, 'Save Changes');
      createCustomMapUiControl(this.ui, 'cancel', svgIconCancel, 'right-top', this.handleCancelChangesButton, 'Cancel Changes');
      createCustomMapUiControl(this.ui, 'overlay', svgIconOverlay, 'right-top', this.handleToggleReferenceRouteOverlay, 'Toggle Reference Route Overlay');

      // Disable some controls for now
      this.setUiControls(false, false, false);

      // Get an instance of the routing service (version 8)
      this.routingService = platform.getRoutingService(null, 8);
    }
  }

  checkUserDetails() {
    let user = this.props.loggedInUser;
    if (user) {
      //console.log("User: " + user.email + " - ", user.isVerified, user.groups, user.customer);
      if (user.groups !== undefined && user.groups.includes('NEC')) {
        // TODO - future special handling for NEC users
      }

      // retrieve the list of routes from AWS S3 storage
      this.updateRouteList(true);
    }
  }

  // retrieve the list of routes from AWS S3 storage
  updateRouteList(alertOnError = false) {
    getAwsRouteList((routeList) => {
      this.routeList = routeList;
      if (routeList === null && alertOnError) {
        this.props.onAlert("Unable to load route list", severityError, 5000, true);
      }
    });
  }

  componentWillUnmount() {
    if (this.map) {
      this.map.removeEventListener('mapviewchange', this.handleMapViewChange);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      lat,
      lng,
      zoom
    } = this.props;

    if (this.map) {
      // prevent the unnecessary map updates by debouncing the
      // setZoom and setCenter calls
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.map.setZoom(zoom);
        this.map.setCenter({ lat, lng });
      }, 100);
    }

    // handle any menu actions from the user
    if (this.props.menuAction) {
      switch (this.props.menuAction) {
        case menuActionOpenRoute:
          this.promptUserBeforeOverwriting = false;
          this.handleLoadRouteRequest(this.props.menuAction);
          break;

        case menuActionUploadRoute:
        case menuActionNewRoute:
          this.promptUserBeforeOverwriting = true;
          this.handleLoadRouteRequest(this.props.menuAction);
          break;

        case menuActionSaveRoute:
        case menuActionDownloadRoute:
          this.handleSaveRouteRequest(this.props.menuAction);
          break;

        case menuActionSaveAsRoute:
          if (this.routeDetails) {
            this.saveAfterEditRouteDetails = true;
            this.promptUserBeforeOverwriting = true;
            this.handleEditRouteDetails(true, true, this.routeDetails);
          }
          break;

        default:
          break;
      }
      this.props.onMenuActionAck();
    }

    if (this.props.loggedInUser !== prevProps.loggedInUser) {
      this.checkUserDetails();
    }

    if (this.props.activeCustomer !== prevProps.activeCustomer) {
      this.updateRouteList(true);
    }
  }

  /**
   * Enable/disable UI controls as required
   * @param {boolean} isRouteLoaded true if a route has been loaded
   * @param {boolean} isEditing true if we are in edit mode
   * @param {boolean} isRouteModified true if the route has been modified
   */
  setUiControls(isRouteLoaded, isEditing, isRouteModified) {
    this.ui.getControl('filter').setVisibility(isEditing);
    this.ui.getControl('filter').setDisabled(!isEditing);
    this.ui.getControl('route').setVisibility(isRouteLoaded);
    this.ui.getControl('route').setDisabled(isEditing);
    this.ui.getControl('edit').setVisibility(isRouteLoaded);
    this.ui.getControl('edit').setDisabled(isEditing || this.readOnlyRoute);
    this.ui.getControl('edit').getElement().title = (this.readOnlyRoute ? 'Edit Route disabled (Route is read-only)' : 'Edit Route');
    this.ui.getControl('clear').setVisibility(isRouteLoaded && !isEditing);
    this.ui.getControl('clear').setDisabled(isEditing);
    this.ui.getControl('undo').setVisibility(isRouteLoaded && isRouteModified && !isEditing);
    this.ui.getControl('undo').setDisabled(isEditing);
    this.ui.getControl('check').setVisibility(isRouteLoaded && isEditing);
    this.ui.getControl('check').setDisabled(!isEditing);
    this.ui.getControl('cancel').setVisibility(isRouteLoaded && isEditing);
    this.ui.getControl('cancel').setDisabled(!isEditing);
    this.ui.getControl('overlay').setVisibility(isRouteLoaded && this.referenceRoutePolyline);
    this.ui.getControl('overlay').setDisabled(false);
  }

  /**
   * Remove the route from the map
   * @param {boolean} clearPlannedRoute true to remove the planned route from the map
   * @param {boolean} disposePlannedRoute true to dispose of the planned route objects
   * @param {boolean} clearCalculatedRoute true to remove the calculated route from the map
   * @param {boolean} clearReferenceRoute true to remove the reference route from the map
   */
  clearMap(clearPlannedRoute, disposePlannedRoute, clearCalculatedRoute, clearReferenceRoute = false) {
    if (this.routeGroup && clearPlannedRoute) {
      if (disposePlannedRoute) {
        for (const obj of this.routeGroup.getObjects())
          obj.dispose();
      }
      this.map.removeObject(this.routeGroup);
      this.routeGroup = null;
    }

    if (this.calculatedRoute && clearCalculatedRoute) {
      this.map.removeObject(this.calculatedRoute.route);
      this.calculatedRoute = null;
    }

    if (this.referenceRoutePolyline !== null && clearReferenceRoute) {
      this.map.removeObject(this.referenceRoutePolyline);
    }
  }

  /**
   * Clear the current route
   */
  clearRoute() {
    this.clearMap(true, true, true, true);
    this.routeDetails = null;
    // Reset the controls
    this.setUiControls(false, false, false);
    this.routeExportPending = false;
    this.routeWasModified = false;
    this.props.onRouteChange();
  }

  setRouteEditable(enable) {
    this.routeEditingEnabled = enable;
    setDraggable(this.stopMarkers, enable);
    this.routeVertices.setVisibility(enable);
    this.setUiControls(true, enable, this.routeWasModified);
    this.handleDrawRoute(true, null);
    this.props.onRouteEditModeChange(!this.readOnlyRoute, enable, this.routeSourceFolder);
    // reset the cursor
    document.body.style.cursor = 'default';
  }

  zoomToRoute() {
    zoomToObject(this.map, this.routeGroup);
  }

  /**
   * Add the planned route to the map
   * @param {boolean} addStopListeners true to add event listeners to the stops group
   * @param {boolean} zoomToRoute true to zoom to make the whole route visible
   */
  addRouteToMap(addStopListeners, zoomToRoute) {
    // add event listeners
    addRouteEventListeners(this.map, this.ui, this.routePolyline, this.routeVertices, this.stopMarkers, addStopListeners,
      this.handleShowStopDetails, this.handleDrawRoute);

    // Add the route polyline and map markers to the map
    this.routeGroup = new H.map.Group({
      volatility: true, // mark the group as volatile for smooth dragging of all it's objects
      objects: [this.routePolyline, this.routeVertices, this.stopMarkers],
      data: mapGroupNamePlannedRoute
    });
    this.routeGroup.setZIndex(10);        // force this group to be at the front

    this.map.addObject(this.routeGroup);

    // Optionally set the map's viewport to make the whole route visible
    if (zoomToRoute) {
      this.zoomToRoute();
    }
  }

  // Load a new route
  loadNewRoute(route) {
    // process the route object, extract linestring and waypoints (stops)
    let { geometry, stops, details } = route;
    let result = createRoutePolyline(geometry);
    this.routePolyline = result.polyline;
    this.routeVertices = result.verticeGroup;
    this.stopMarkers = createStopMarkers(stops);

    // add the route to the map
    this.addRouteToMap(true, true);
    this.routeWasModified = false;
    this.routeExportPending = false;
    this.props.onRouteChange(details);
    // deep copy the route details object so we can edit it while retaining the original
    this.routeDetails = JSON.parse(JSON.stringify(details));
  }

  // Route file selected handler
  handleRouteFileSelected(fileName, alertMessage, route, fromAws = false) {
    const onAlert = this.props.onAlert;
    this.clearMap(true, true, true, true);
    this.routeDetails = null;
    this.fileName = null;
    this.referenceRoutePolyline = null;
    this.props.onRouteChange();
    //console.log("handleRouteFileSelected: " + fileName, alertMessage, route);
    if (route && !alertMessage) {
      this.fileName = fileName;
      this.readOnlyRoute = fromAws ? routeIsReadOnly(fileName) : false;
      this.routeSourceFolder = fromAws ? getRouteSourceFolder(fileName) : null;
      this.route = route;
      this.originalRoute = extractRouteFromGeoJSON(this.route);
      this.loadNewRoute(this.originalRoute);
      console.log("Loaded file: " + fileName + " with " + this.routeVertices.getObjects().length + " points and " +
        this.stopMarkers.getObjects().length + " stops");
      if (!this.readOnlyRoute) {
        // make sure the stop sequence numbers are correct
        let updateCount = updateStopSequenceNumbers(this.stopMarkers.getObjects());
        if (updateCount > 0) {
          console.log(updateCount + " stop sequence numbers were automatically updated");
          this.routeExportPending = true;
        }
      } else if (storageFolderReview.startsWith(this.routeSourceFolder)) {
        // we have loaded a route that is under review - check for any issues with the route and alert the user immediately
        let routeIssues = validateRoute(this.routeVertices, this.stopMarkers);
        if (routeIssues.length > 0) {
          console.warn('Route is not valid: ' + routeIssues.length + ' issue(s) found)');
          for (const i of routeIssues) {
            console.info(i);
          }
          // display a list of issues to the user so they can decide how to proceed
          this.setState({ routeIssueList: routeIssues });
        } else {
          // no issues found
          this.setState({ routeIssueList: null });
        }
      }

      onAlert("Loaded route " + this.routeDetails.route + " - " + this.routeDetails.destination + " with " + this.routeVertices.getObjects().length + " points and " +
        this.stopMarkers.getObjects().length + " stops" + (this.readOnlyRoute ? " (read-only)" : ""), severitySuccess, 10000, true);
      // Reset the controls
      this.setUiControls(true, false, false);
      this.props.onRouteEditModeChange(!this.readOnlyRoute, false, this.routeSourceFolder);

      // Read the reference route from AWS as well, if required
      if (fromAws) {
        let idx = fileName.lastIndexOf('/');
        let referenceFileName = storageFolderReference.concat(fileName.substring(idx + 1));
        retrieveRouteFromAws(referenceFileName, this.handleReferenceRouteLoaded);
      }
    } else {
      onAlert(alertMessage, severityError, 0);
    }
  }

  // Reference route file has been loaded
  handleReferenceRouteLoaded(fileName, errorMessage, route, fromAws = false) {
    this.referenceRoutePolyline = null;
    if (route && !errorMessage && fromAws) {
      let refRoute = extractRouteFromGeoJSON(route);
      this.referenceRoutePolyline = createReferenceRoutePolyline(refRoute.geometry);
      this.referenceRoutePolyline.setVisibility(false);
      this.referenceRoutePolyline.setZIndex(5);
      this.map.addObject(this.referenceRoutePolyline);
      console.log("Loaded reference route: " + fileName);
      // Reset the map controls
      this.setUiControls(true, false, false);
    } else {
      console.log("Unable to load reference route: " + fileName + " (" + errorMessage + ")");
    }
  }

  // Handle the route calculation result - display the calculated route on the map
  handleRouteCalculationResult(result, errorMessage) {
    const onAlert = this.props.onAlert;
    this.props.showCircularProgressBar(false);
    if (result) {
      this.calculatedRoute = result;
      this.calculatedRoute.route.setZIndex(1);    // send the calculated route to the back
      this.map.addObject(result.route);
      // Zoom to the desired route shape
      //this.zoomToRoute();

      let alertMessage = "Route calculation successful";
      if (result.length && result.duration) {
        alertMessage = "Route calculated: length = " + result.length + "km, duration = " + result.duration + " minutes (approx)";
      }
      onAlert(null, severityError, 0);
      onAlert(alertMessage, severitySuccess, 5000, true);
    } else {
      // error
      console.warn(errorMessage);
      onAlert(errorMessage, severityError, 0);
    }
  }

  handleDrawRoute(set, index) {
    if (set) {
      this.drawRouteIndex = index;
    }
    return this.drawRouteIndex;
  }

  /**
   * Show the details of a stop
   * @param {boolean} show true to show the stop details dialog, otherwise hide it
   * @param {boolean} edit true to allow the stop details to be edited
   * @param {{}} stop the stop data to be edited/saved
   * @param {boolean} ok true if the changes should be saved, otherwise discard them
   */
  handleShowStopDetails(show, edit, stop, ok) {
    if (show && stop) {
      this.setState({ editStopValues: { stopDetails: stop, edit: edit } });
    } else {
      if (stop) {
        let index = stop.index;
        let isNewStop = stop.isNew;
        if (ok) {
          if (index < this.stopMarkers.getObjects().length) {
            // remove unwanted metadata from the stop data object
            delete stop.index;
            delete stop.isNew;
            // update the stop data
            this.stopMarkers.getObjects()[index].setData(stop);
          }
        } else if (isNewStop) {
          // user cancelled the edit for a newly created stop, so we should delete the stop
          deleteRouteStop(this.stopMarkers, index);
        }
      }
      this.setState({ editStopValues: { stopDetails: {}, edit: false } });
    }
  }

  /**
   * Edit the details of the route
   * @param {boolean} show true to show the route details dialog
   * @param {boolean} edit true to allow editing of the route details, otherwise read-only
   * @param {Object} details the route data to be edited/saved
   * @param {boolean} ok true if the changes should be saved, otherwise discard them
   */
  handleEditRouteDetails(show, edit, details, ok) {
    if (show && details) {
      if (edit) {
        this.editRouteDialogTitle = this.saveAfterEditRouteDetails ? "Save As Route" : "Edit Route Details";
      } else {
        this.editRouteDialogTitle = "Route Details";
      }
      this.setState({ editRouteValues: { details: details, edit: edit } });
    } else {
      if (ok && details) {
        for (const key in details) {
          this.routeDetails[key] = details[key];
        }
        this.props.onRouteChange(this.routeDetails);
        if (this.saveAfterEditRouteDetails) {
          this.handleSaveRouteRequest(menuActionSaveRoute);
        }
      }
      this.setState({ editRouteValues: { details: {}, edit: false } });
    }
  }

  // Filter button handler
  handleFilterButton(evt) {
    const onAlert = this.props.onAlert;
    if (evt.currentTarget.getState() === 'down') {
      if (maxRouteWaypoints < this.stopMarkers.getObjects().length) {
        onAlert("Unable to create filtered route : number of stops (" + this.stopMarkers.getObjects().length + ") is > " + maxRouteWaypoints, severityError, 0);
        return;
      }

      // create a filtered version of the current route shape
      let result = createFilteredRoutePolyline(this.routeVertices.getObjects(), this.stopMarkers.getObjects());
      if (result) {
        // remove existing route objects from the map
        this.clearMap(true, false, false, false)
        this.routeVertices.dispose();
        // get the new route details (note: we reuse the stop markers from before)
        this.routePolyline = result.polyline;
        this.routeVertices = result.verticeGroup;
        this.routeVertices.setVisibility(true);

        // add the route to the map
        this.addRouteToMap(false, false);
        onAlert("Created filtered route with " + this.routeVertices.getObjects().length + " waypoints", severityInfo);
      } else {
        onAlert("Unable to create filtered route", severityError, 0);
      }
    }
  }

  // Handle a request to load a route
  handleLoadRouteRequest(action) {
    if (this.routeExportPending) {
      // prompt the user for confirmation before overwriting a modified route
      this.showAlertDialog(true, null, 'The modified route has not been saved - discard all changes?',
        () => {
          console.log('Discarding all changes and loading a new route');
          this.showAlertDialog(false);
          this.loadRoute(action);
        },
        () => {
          this.showAlertDialog(false);
        });
    } else {
      this.loadRoute(action);
    }
  }

  // Load a new route
  loadRoute(action) {
    switch (action) {
      case menuActionOpenRoute:
        // Retrieve a route from AWS cloud storage
        this.showRouteSelectionDialog(true);
        break;

      case menuActionUploadRoute:
        // Upload a file from the local machine
        selectFile(".geojson", false).then((file) => {
          readGeoJSONFile(file, this.handleRouteFileSelected)
        });
        break;

      case menuActionNewRoute:
        // let route = { geometry: [], stops: [], details: {} }
        // this.loadNewRoute(route);
        // this.setRouteEditable();
        break;

      default:
        break;
    }
  }

  // Handle a request to save the currently loaded route
  handleSaveRouteRequest(action) {
    const onAlert = this.props.onAlert;
    // rebuild the filename
    this.fileName = getFilenameForGeoJSON(this.routeDetails, this.fileName);

    if (action === menuActionSaveRoute && this.promptUserBeforeOverwriting) {
      // Refresh the list of routes
      getAwsRouteList((routeList) => {
        this.routeList = routeList;
        if (routeList !== null) {
          // check if the file already exists
          if (routeExistsInAws(this.fileName, this.routeList)) {
            // the file exists - prompt the user before overwriting
            this.showAlertDialog(true, 'Warning', 'This route already exists - overwrite?',
              () => {
                // user confirmed to overwrite - save it now
                this.showAlertDialog(false);
                this.promptUserBeforeOverwriting = false;
                this.handleSaveRouteRequest(menuActionSaveRoute);
              },
              () => {
                // user cancelled the request
                this.showAlertDialog(false);
              });
          } else {
            // the file doesn't exist - proceed to save it
            this.promptUserBeforeOverwriting = false;
            this.handleSaveRouteRequest(menuActionSaveRoute);
          }
        } else {
          // error
          onAlert("Unable to save route", severityError, 0);
        }
      });
      return;
    }

    let route = prepareRouteForExport(this.routePolyline, this.routeVertices, this.stopMarkers);
    route.details = this.routeDetails;
    let geoJson = routeToGeoJSON(route);
    // Create a file blob for export
    const blob = new Blob([geoJson], { type: "text/plain" });

    if (action === menuActionSaveRoute) {
      // Save the file to AWS cloud storage
      saveRouteToAws(this.routeDetails, this.fileName, blob, null, (errorMessage) => {
        if (errorMessage) {
          onAlert("Unable to save route - " + errorMessage, severityError, 0);
        } else {
          this.routeExportPending = false;
          onAlert("Route saved", severitySuccess, 3000, true);
          // Refresh the list of routes
          this.updateRouteList();
          this.readOnlyRoute = routeIsReadOnly(this.fileName);
          this.routeSourceFolder = storageFolderDraft;
          // Reset the controls
          this.setUiControls(true, false, this.routeWasModified);
          this.props.onRouteEditModeChange(!this.readOnlyRoute, false, this.routeSourceFolder);
        }
      });
    } else if (action === menuActionDownloadRoute) {
      exportFile(this.fileName, blob);
      this.routeExportPending = false;
    }
  }

  calculateRouteNow() {
    console.log('Calculate route');
    // remove any previously calculated route from the map
    this.clearMap(false, false, true, false);
    // show progress spinner
    this.props.showCircularProgressBar(true);
    // calculate the route
    calculateRoute(this.routingService, this.routeVertices.getObjects(), this.stopMarkers.getObjects(), this.handleRouteCalculationResult);
  }

  // Calculate route button handler
  handleCalculateRouteButton(evt) {
    if (evt.currentTarget.getState() === 'down') {
      // warn the user if there are too many route points
      if (this.routeVertices.getObjects().length > maxRouteWaypoints) {
        this.showAlertDialog(true, 'Warning', 'The route calculation may fail due to excessive number of waypoints (' +
          this.routeVertices.getObjects().length + ').  Continue anyway?',
          () => {
            this.showAlertDialog(false);
            this.calculateRouteNow();
          },
          () => {
            this.showAlertDialog(false);
          });
      } else {
        this.calculateRouteNow();
      }
    }
  }

  // Edit route button handler
  handleEditRouteButton(evt) {
    if (evt.currentTarget.getState() === 'down' && !this.readOnlyRoute) {
      console.log('Edit route');
      // make a copy of the current route values so we can restore it later if the user cancels their changes
      let { vertices, stops, details } = initialisePreviousRouteValues(
        this.routePolyline, this.routeVertices, this.stopMarkers, this.routeDetails);
      this.previousStops = stops;
      this.previousVertices = vertices;
      this.previousRouteDetails = details;
      this.setRouteEditable(true);
    }
  }

  // Clear route button handler
  handleClearRouteButton(evt) {
    if (evt.currentTarget.getState() === 'down') {
      if (this.routeExportPending) {
        // prompt the user for confirmation before discarding the changes
        this.showAlertDialog(true, null, 'The modified route has not been saved - discard all changes?',
          () => {
            console.log('Discarding all changes and clearing the route');
            this.clearRoute();
            this.showAlertDialog(false);
          },
          () => {
            this.showAlertDialog(false);
          });
      } else {
        console.log('Clear route');
        this.clearRoute();
      }
    }
  }

  // Undo button handler - disacrds all changes made since the route was loaded
  handleUndoButton(evt) {
    if (evt.currentTarget.getState() === 'down') {
      // check if changes have been made
      if (this.routeWasModified) {
        // prompt the user for confirmation before discarding the changes
        this.showAlertDialog(true, null, 'Discard all changes and revert to the original route?',
          () => {
            console.log('Discarding all changes and reverting to the original route');
            let exportPending = this.routeExportPending;
            this.clearMap(true, true, true, true);
            this.loadNewRoute(this.originalRoute);
            this.routeExportPending = !exportPending;
            // Reset the controls
            this.setUiControls(true, false, false);
            this.showAlertDialog(false);
          },
          () => {
            this.showAlertDialog(false);
          });
      }
    }
  }

  // Cancel route edits button handler
  handleCancelChangesButton(evt) {
    if (evt.currentTarget.getState() === 'down') {
      // check if changes have been made
      if (wasRouteModified(this.routeVertices, this.stopMarkers, this.routeDetails,
        this.previousVertices, this.previousStops, this.previousRouteDetails)) {
        // prompt the user for confirmation before discarding the changes
        this.showAlertDialog(true, null, 'Discard unsaved changes?',
          () => {
            console.log('Discarding unsaved changes');
            restorePreviousRouteValues(this.routePolyline, this.routeVertices, this.stopMarkers,
              this.routeDetails, this.previousVertices, this.previousStops, this.previousRouteDetails);
            this.setRouteEditable(false);
            this.showAlertDialog(false);
            this.props.onRouteChange(this.routeDetails);
          },
          () => {
            this.showAlertDialog(false);
          });
      } else {
        console.log('Route is unchanged');
        this.setRouteEditable(false);
      }
    }
  }

  // Save route edits button handler
  handleSaveChangesButton(evt) {
    if (evt.currentTarget.getState() === 'down') {
      if (wasRouteModified(this.routeVertices, this.stopMarkers, this.routeDetails,
        this.previousVertices, this.previousStops, this.previousRouteDetails)) {
        // check for any issues with the edited route
        let routeIssues = validateRoute(this.routeVertices, this.stopMarkers);
        if (routeIssues.length > 0) {
          console.warn('Route is not valid: ' + routeIssues.length + ' issue(s) found)');
          for (const i of routeIssues) {
            console.info(i);
          }
          // display a list of issues to the user so they can decide how to proceed
          this.setState({ routeIssueList: routeIssues });
        } else {
          // no issues found - accept the route modifications
          this.acceptRouteModifications();
        }
      } else {
        // no changes were made
        this.setRouteEditable(false);
      }
    }
  }

  // Toggle reference route overlay button handler
  handleToggleReferenceRouteOverlay(evt) {
    if (evt.currentTarget.getState() === 'down') {
      if (this.referenceRoutePolyline !== null) {
        this.referenceRoutePolyline.setVisibility(!this.referenceRoutePolyline.getVisibility());
      }
    }
  }

  // Accept modifications made to the route during editing
  acceptRouteModifications() {
    console.log('Saving route modifications');
    this.routeWasModified = true;
    this.routeExportPending = true;
    this.setRouteEditable(false);
  }

  // Map view changed handler - notify any interested external parties
  handleMapViewChange = (ev) => {
    const {
      onMapViewChange
    } = this.props;
    if (ev.newValue && ev.newValue.lookAt) {
      const lookAt = ev.newValue.lookAt;
      // adjust precision
      const lat = Math.trunc(lookAt.position.lat * 1E7) / 1E7;
      const lng = Math.trunc(lookAt.position.lng * 1E7) / 1E7;
      const zoom = Math.trunc(lookAt.zoom * 1E2) / 1E2;
      onMapViewChange(zoom, lat, lng);
    }
  }

  /**
   * Show the alert dialog
   * @param {boolean} show true to show the dialog, false to hide it
   * @param {string} title the title to display in the dialog 
   * @param {string} content the text to display in the dialog
   * @param {function} onOk callback function for when OK is clicked
   * @param {function} onCancel callback function for when cancel is clicked
   */
  showAlertDialog(show, title, content, onOk, onCancel) {
    if (show) {
      this.alertDialogCallbackOK = onOk;
      this.alertDialogCallbackCancel = onCancel;
      this.setState({ alertDialogContent: content, alertDialogTitle: title });
    } else {
      this.setState({ alertDialogContent: null, alertDialogTitle: null });
    }
  }

  /**
   * Show the route selection dialog
   * @param {boolean} show true to show the dialog, false to hide it
   */
  showRouteSelectionDialog(show) {
    if (this.routeList) {
      this.setState({ routeSelectionDialogEnabled: show || false });
    } else {
      const onAlert = this.props.onAlert;
      onAlert("No routes available", severityWarning, 5000, true);
    }
  }

  render() {
    const {
      alertDialogContent,
      alertDialogTitle,
      routeSelectionDialogEnabled,
      editStopValues,
      editRouteValues,
      routeIssueList
    } = this.state;

    return (
      <div
        style={{ position: 'relative', width: '100%', height: this.mapHeight, minHeight: '500px' }}
        ref={this.ref} >

        <AlertDialog
          title={alertDialogTitle}
          content={alertDialogContent}
          onOk={this.alertDialogCallbackOK}
          onCancel={this.alertDialogCallbackCancel}
        />

        <EditStopDialog
          values={editStopValues.stopDetails}
          edit={editStopValues.edit}
          onOk={(result) => { this.handleShowStopDetails(false, editStopValues.edit, result, true) }}
          onCancel={(result) => { this.handleShowStopDetails(false, editStopValues.edit, result, false) }}
        />

        <EditRouteDetailsDialog
          title={this.editRouteDialogTitle}
          values={editRouteValues.details}
          edit={editRouteValues.edit}
          onOk={(details) => { this.handleEditRouteDetails(false, false, details, true) }}
          onCancel={(details) => { this.handleEditRouteDetails(false, false, details, false) }}
        />

        <SelectAwsRouteDialog
          show={routeSelectionDialogEnabled}
          content={this.routeList}
          onOk={(key) => {
            if (key != null) {
              // Read the route from AWS
              retrieveRouteFromAws(key, this.handleRouteFileSelected);
            }
            this.showRouteSelectionDialog(false);
          }}
        />

        <RouteIssueDialog
          issueList={routeIssueList}
          showCancelButton={this.routeEditingEnabled}
          onOk={() => {
            this.setState({ routeIssueList: null });
            if (this.routeEditingEnabled) {
              this.acceptRouteModifications();
            }
          }}
          onCancel={() => { this.setState({ routeIssueList: null }) }}
        />
      </div>
    )
  }
}
