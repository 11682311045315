/**
 * GeoJSON file utility functions
 * @author David Kirkland <david.kirkland@nec.com.au>
 */
import { readFile } from "./FileUtils";

const geoJsonExtension = '.geojson',
    geoJsonFileNameSeparator = '-_-',
    geoJsonOldFileNameSeparator = '_';

/**
 * Extract route details from a GeoJSON string
 * @param {string} geoJsonObject the GeoJSON string to be processed
 * @returns {{geometry:{lat:number, lng:number}[], stops:{lat:number, lng:number}[], details:{*}}}
 */
function extractRouteFromGeoJSON(geoJsonObject) {
    if (geoJsonObject.type !== "FeatureCollection" || !("features" in geoJsonObject)) {
        return;
    }

    let route = { geometry: [], stops: [], details: {} }
    let stopCount = 0;
    for (const feature of geoJsonObject.features) {
        let geometry = feature.geometry;
        let properties = feature.properties;
        if (geometry.type === "LineString") {
            // this is the precomputed route details
            let coordinates = geometry.coordinates;
            route.details.route = properties.route || "N/A";
            route.details.destination = properties.destination || "N/A";
            // load the variant (for backwards compatibility read the id if the variant is not set)
            route.details.variant = properties.variant || properties.id || "N/A";  // the route variant
            console.log("Loaded linestring for route " + route.details.route + " - " + route.details.destination + " (variant: " +
                route.details.variant + ") with " + coordinates.length + " points");

            for (const key of Object.keys(properties)) {
                //console.debug("Got property: " + key + " = " + properties[key]);

                // check for some optional properties that can be used to adjust the route generation process
                if (key === "waypointDistanceInterval") {
                    // specifies the minimum distance between waypoints
                    let waypointDistanceInterval = properties[key];
                    if (waypointDistanceInterval < 20) {
                        // prevent ridiculously small values
                        waypointDistanceInterval = 20;
                    }
                }
            }

            //Extract the co-ordinates and format as an array of position objects
            for (const c of coordinates) {
                route.geometry.push({ lat: c[1], lng: c[0] })
            }

        } else if (geometry.type === "Point") {
            // this is usually the details of a stop,
            // but could be a special point we added to make the routing engine calculate the route correctly
            let coordinates = geometry.coordinates;
            let point = {};
            point.lat = coordinates[1];
            point.lng = coordinates[0];

            if (properties.stopName) {
                stopCount++;
                // this is the location of a bus stop so we want to add a map marker for it
                //console.log("Loaded stop " + properties.stopName + " at: " + point.lat + ", " + point.lng);
            } else {
                // this is a point to assist with routing (not a bus stop)
                //console.log("Loaded non-stop waypoint at: " + point.lat + ", " + point.lng);
            }
            for (const key of Object.keys(properties)) {
                point[key] = properties[key];
            }
            route.stops.push(point);
        } else {
            console.warn("Unexpected geometry type: " + geometry.type);
        }
    }
    console.log("Loaded details for " + route.stops.length + " stopover waypoints (" + stopCount + " bus stops)");
    return route;
}

/**
 * Convert a route to GeoJSON format
 * @param {{geometry: {lat:number, lng:number}[], stops: {lat:number, lng:number}[], details: {}}} route the route to convert
 * @returns {string} the route as a GeoJSON string
 */
function routeToGeoJSON(route) {
    let { geometry, stops, details } = route;
    let geoJson = {};
    geoJson.type = "FeatureCollection";
    geoJson.features = [];

    // Process the geometry
    let f = { type: "Feature", properties: {}, geometry: { type: "LineString", coordinates: [] } };
    for (const key in details) {
        if (details[key] !== "N/A") {
            f.properties[key] = details[key];
        }
    }
    geometry.forEach((point) => f.geometry.coordinates.push([point.lng, point.lat]));
    geoJson.features.push(f);

    stops.forEach((stop) => {
        let s = { type: "Feature", properties: {}, geometry: { type: "Point", coordinates: [stop.lng, stop.lat] } };
        for (const key in stop) {
            if (key !== "lng" && key !== "lat") {
                s.properties[key] = stop[key];
            }
        }
        geoJson.features.push(s);
    });

    return JSON.stringify(geoJson, null, 2);
}

/**
 * Read the contents of a GeoJSON file
 * @param {*} file the file to open
 * @param {*} callBack the function to call after opening the file
 */
function readGeoJSONFile(file, callBack) {
    readFile(file, (content) => {
        if (content === null) {
            callBack(file.name, "Error: unknown error", null);
        } else {
            let geoJson = parseGeoJSONString(file.name, content);
            callBack(file.name, geoJson.error, geoJson.obj);
        }
    });
};

/**
 * Convert a JSON string into an object
 * @param {string} fileName the name of the file being parsed
 * @param {string} callBack the contents of the file being parsed
 * @returns {{error:string, obj:{*}}}
 */
function parseGeoJSONString(fileName, fileContent) {
    let result = {
        error: null,
        obj: null
    }
    try {
        result.obj = JSON.parse(fileContent);
        console.log("Parsed " + fileName, result.obj);
        if (result.obj.type !== 'FeatureCollection' || !('features' in result.obj)) {
            result.error = "Error: invalid GeoJSON format";
        }
    } catch (err) {
        result.error = "Error: " + err.message;
    }
    return result;
}

/**
 * Get the GeoJSON filename for a route
 * @param {*} routeDetails the route details
 * @param {string} defaultName the default name to use if route details is empty
 * @returns {string} the filename for the route
 */
function getFilenameForGeoJSON(routeDetails, defaultName) {
    let fileName = defaultName || "route";
    if (routeDetails) {
        // rebuild the filename from the route details
        fileName = routeDetails.route + geoJsonFileNameSeparator + routeDetails.destination;
        if (routeDetails.variant)
            fileName += geoJsonFileNameSeparator + routeDetails.variant;
    }

    if (!fileName.endsWith(geoJsonExtension)) {
        fileName += geoJsonExtension;
    }

    return fileName;
}

/**
 * Extract the route details from a GeoJSON filename
 * @param {*} fileName the filename for the route
 * @returns {*} the route details
 */
function getRouteDetailsFromGeoJSONFilename(fileName) {
    let route = null,
        destination = null,
        variant = null;

    if (fileName.toLowerCase().endsWith('.geojson')) {
        // remove the geojson file extension
        fileName = fileName.slice(0, -8);
    }
    // Split the filename based on the expected separator, with fallback to the old separator if necessary
    let separators = [geoJsonFileNameSeparator, geoJsonOldFileNameSeparator];
    for (const sep of separators) {
        let arr = fileName.split(sep);
        let len = arr.length;
        if (len >= 2) {
            // route number
            route = arr[0];
            // route destination
            destination = arr[1];
            if (len >= 3) {
                // reinstate possible separators in the destination name
                for (let i = 2; i < (len - 1); i++) {
                    destination += sep + arr[i];
                }
                // route variant
                variant = arr[len - 1];
            }
            return { route, destination, variant };
        }
    }
    return null;
}

export {
    extractRouteFromGeoJSON, routeToGeoJSON, parseGeoJSONString, readGeoJSONFile, 
    getFilenameForGeoJSON, getRouteDetailsFromGeoJSONFilename, geoJsonExtension
}