/**
 * Dialogs used for route import functions
 * @author David Kirkland <david.kirkland@nec.com.au>
 */
import { useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from '@mui/material/Slider';
import { ScrollView, Text } from '@aws-amplify/ui-react';

/**
 * Create a dialog with a linear progress bar, including a label
 * @param {{}} props 
 * @returns dialog to be rendered
 */
function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

/**
 * Create a dialog to display the import progress
 * @param {{}} props 
 * @returns dialog to be rendered
 */
function ImportProgress(props) {
    return (
        <Dialog
            open={props.percentComplete !== null}
            aria-labelledby="import-progress-dialog-title"
            aria-describedby="import-progress-dialog-description"
            fullWidth={true}
            maxWidth={"sm"}
        >
            <DialogTitle id="import-progress-dialog-title">
                Import Progress
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="import-progress-dialog-description" sx={{ whiteSpace: 'pre-line' }}>
                    {"Importing " + props.currentRoute}
                </DialogContentText>
                <LinearProgressWithLabel value={(props.percentComplete === null ? 0 : props.percentComplete)} />
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
                <Button variant="contained" style={{ minWidth: '6rem' }} onClick={props.onCancel} autoFocus>Cancel</Button>
            </DialogActions>
        </Dialog>

    );
}

/**
 * Create a dialog to request confirmation from the user before commencing an import
 * @param {{}} props 
 * @returns dialog to be rendered
 */
function ImportConfirmation(props) {
    const [overwriteEnabled, setOverwriteEnabled] = useState(true),
        [autoFilterEnabled, setAutoFilterEnabled] = useState(false),
        [autoFilterValue, setAutoFilterValue] = useState(3),
        [autoReviewEnabled, setAutoReviewEnabled] = useState(false);

    const handleOverwriteChange = (event) => {
        setOverwriteEnabled(event.target.checked);
    };

    const handleAutoFilterChange = (event) => {
        setAutoFilterEnabled(event.target.checked);
    };

    const handleAutoFilterSliderChange = (event, newValue) => {
        setAutoFilterValue(newValue);
    };

    const handleAutoReviewChange = (event) => {
        setAutoReviewEnabled(event.target.checked);
    };

    const handleOk = () => {
        props.onOk(overwriteEnabled, autoFilterEnabled ? autoFilterValue : null, autoFilterEnabled ? autoReviewEnabled : false);
    };

    return (
        <Dialog
            open={props.show}
            aria-labelledby="import-confirmation-dialog-title"
        >
            <DialogTitle id="import-confirmation-dialog-title">
                {"Confirm import of " + props.count + " route" + (props.count > 1 ? "s" : "")}
            </DialogTitle>
            <DialogContent>
                <FormControlLabel
                    control={<Checkbox
                        checked={overwriteEnabled}
                        onChange={handleOverwriteChange}
                        inputProps={{ 'aria-label': 'overwrite-checkbox' }}
                    />}
                    label="Overwrite existing routes" />
                <br />
                <FormControlLabel
                    control={<Checkbox
                        checked={autoFilterEnabled}
                        onChange={handleAutoFilterChange}
                        inputProps={{ 'aria-label': 'autofilter-checkbox' }}
                    />}
                    label="Auto filter imported routes" />
                <br />
                <FormControlLabel
                    control={<Checkbox
                        checked={autoReviewEnabled}
                        onChange={handleAutoReviewChange}
                        inputProps={{ 'aria-label': 'autoreview-checkbox' }}
                        disabled={!autoFilterEnabled}
                    />}
                    label="Send filtered routes for review" />
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="subtitle2">
                            Filter Setting
                        </Typography>
                    </Box>
                    <Slider
                        value={autoFilterValue}
                        step={1}
                        marks
                        min={1}
                        max={5}
                        disabled={!autoFilterEnabled}
                        valueLabelDisplay='off'
                        onChange={handleAutoFilterSliderChange}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="caption">
                            Minimal
                        </Typography>
                        <Typography variant="caption">
                            Aggressive
                        </Typography>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
                <Button variant="outlined" style={{ minWidth: '6rem' }} onClick={props.onCancel}>Cancel</Button>
                <Button variant="contained" style={{ minWidth: '6rem' }} onClick={handleOk} autoFocus>OK</Button>
            </DialogActions>
        </Dialog>

    );
}

/**
 * Create a dialog to display a summary of the import after it has completed
 * @param {{}} props 
 * @returns dialog to be rendered
 */
function ImportSummary(props) {
    const importedRoutes = props.imported.map(route => <Text key={route}>{route}</Text>);
    const skippedRoutes = props.skipped.map(route => <Text key={route}>{route}</Text>);
    const failedRoutes = props.failed.map(route => <Text key={route}>{route}</Text>);
    const total = props.imported.length + props.skipped.length + props.failed.length;

    return (
        <Dialog
            open={props.show}
            aria-labelledby="import-summary-dialog-title"
            fullWidth={true}
            maxWidth={"sm"}
        >
            <DialogTitle id="import-summary-dialog-title">
                <u>{"Import Summary - " + total + " route" + (total > 1 ? "s" : "") + " processed"}</u>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <DialogContentText >
                        <b>{"Successful: " + props.imported.length}</b>
                    </DialogContentText>
                    {
                        props.imported.length > 0 &&
                        <ScrollView height="120px" backgroundColor="green.20">
                            {importedRoutes}
                        </ScrollView>
                    }
                </Box>
                <Box>
                    <DialogContentText >
                        <br /><b>{"Skipped: " + props.skipped.length}</b>
                    </DialogContentText>
                    {
                        props.skipped.length > 0 &&
                        <ScrollView height="120px" backgroundColor="orange.20">
                            {skippedRoutes}
                        </ScrollView>
                    }
                </Box>
                <Box>
                    <DialogContentText >
                        <br /><b>{"Failed: " + props.failed.length}</b>
                    </DialogContentText>
                    {
                        props.failed.length > 0 &&
                        <ScrollView height="120px" backgroundColor="red.20">
                            {failedRoutes}
                        </ScrollView>
                    }
                </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
                <Button variant="contained" style={{ minWidth: '6rem' }} onClick={props.onOk} autoFocus>OK</Button>
            </DialogActions>
        </Dialog>
    );
}

export {
    ImportProgress, ImportConfirmation, ImportSummary
};