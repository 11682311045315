/**
 * File utility functions
 * @author David Kirkland <david.kirkland@nec.com.au>
 */

/**
 * Open a select file dialog
 * @param {string} contentType the file types to accept
 * @param {boolean} multiple true if we will accept multiple files
 */
function selectFile(contentType, multiple) {
    return new Promise(resolve => {
        let input = document.createElement('input');
        input.type = 'file';
        input.multiple = multiple;
        input.accept = contentType;

        input.onchange = _ => {
            let files = Array.from(input.files);
            if (multiple)
                resolve(files);
            else
            resolve(files[0]);
        };

        input.click();
    });
}

/**
 * Read the contents of a file
 * @param {*} file the file to open
 * @param {*} callBack the function to call after opening the file
 */
function readFile(file, callBack) {
    let reader = new FileReader();
    reader.onload = readerEvent => {
        if (!readerEvent?.target?.result) {
            callBack(null);
        } else {
            callBack(readerEvent.target.result);
        }
    }
    reader.readAsText(file, 'UTF-8');
};

/**
 * Export a file for download to the local machine
 * @param {*} fileName the name of the file
 * @param {*} blob the contents of the file to be exported
 */
function exportFile(fileName, blob) {
    if (fileName.includes('/')) {
        // remove leading folders
        fileName = fileName.slice(fileName.lastIndexOf('/') + 1);
    }

    // Download the file to the local machine
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = fileName || "export.geojson";
    link.href = url;
    link.click();    
}

export { selectFile, readFile, exportFile }