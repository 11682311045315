// Icons from https://www.svgrepo.com

const svgIconEdit = '<svg class="H_icon H_icon" viewBox="0 0 490.305 490.305">' +
    '<path d="M472.469,81.443l-63.6-63.6c-13.1-16.4-53.2-30.2-83.4,0l-290.9,289.9l0,0c-4.4,4.4-6.5,10.1-6.2,15.6l-27.1,141.8' +
    'c-4.2,16.2,11.9,26.6,22.9,25l147-29.2c4.2,0,7.3-2.1,10.4-5.2l290.9-289.8C495.469,142.943,495.469,104.443,472.469,81.443z' +
    'M354.669,46.043c6.3-7.3,18.8-7.3,26.1,0l17.3,17l-289.7,289.7l-30.1-30.4L354.669,46.043z M61.769,364.043l64.4,64.4l-80.1,15.8' +
    'L61.769,364.043z M444.369,135.643l-276.8,276.8l-30.1-30.4l290-290l16.8,16.5C453.469,118.343,449.169,130.743,444.369,135.643z"' +
    '/></svg>';

const svgIconImport = '<svg class="H_icon H_icon" viewBox="0 0 60.903 60.903">' +
    '<path d="M49.561,16.464H39.45v6h10.111c3.008,0,5.341,1.535,5.341,2.857v26.607c0,1.321-2.333,2.858-5.341,2.858H11.34' +
    'c-3.007,0-5.34-1.537-5.34-2.858V25.324c0-1.322,2.333-2.858,5.34-2.858h10.11v-6H11.34C4.981,16.466,0,20.357,0,25.324v26.605' +
    'c0,4.968,4.981,8.857,11.34,8.857h38.223c6.357,0,11.34-3.891,11.34-8.857V25.324C60.902,20.355,55.921,16.464,49.561,16.464z"/>' +
    '<path d="M39.529,29.004c-0.768,0-1.535,0.294-2.121,0.88l-3.756,3.755V20.612v-6V3.117c0-1.656-1.343-3-3-3s-3,1.344-3,3v11.494v6' +
    'v13.23l-3.959-3.958c-0.586-0.586-1.354-0.88-2.121-0.88s-1.535,0.294-2.121,0.88c-1.172,1.17-1.172,3.07,0,4.241l8.957,8.957' +
    'c0.586,0.586,1.354,0.877,2.12,0.877c0.008,0,0.016,0,0.023,0s0.015,0,0.022,0c0.768,0,1.534-0.291,2.12-0.877l8.957-8.957' +
    'c1.172-1.171,1.172-3.071,0-4.241C41.064,29.298,40.298,29.004,39.529,29.004z"/></svg>';

const svgIconExport = '<svg class="H_icon H_icon" viewBox="0 0 67.671 67.671">' +
    '<path d="M52.946,23.348H42.834v6h10.112c3.007,0,5.34,1.536,5.34,2.858v26.606c0,1.322-2.333,2.858-5.34,2.858H14.724' +
    'c-3.007,0-5.34-1.536-5.34-2.858V32.207c0-1.322,2.333-2.858,5.34-2.858h10.11v-6h-10.11c-6.359,0-11.34,3.891-11.34,8.858v26.606' +
    'c0,4.968,4.981,8.858,11.34,8.858h38.223c6.358,0,11.34-3.891,11.34-8.858V32.207C64.286,27.239,59.305,23.348,52.946,23.348z"/>' +
    '<path d="M24.957,14.955c0.768,0,1.535-0.293,2.121-0.879l3.756-3.756v13.028v6v11.494c0,1.657,1.343,3,3,3s3-1.343,3-3V29.348v-6' +
    'V10.117l3.959,3.959c0.586,0.586,1.354,0.879,2.121,0.879s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242l-8.957-8.957' +
    'C35.492,0.291,34.725,0,33.958,0c-0.008,0-0.015,0-0.023,0s-0.015,0-0.023,0c-0.767,0-1.534,0.291-2.12,0.877l-8.957,8.957' +
    'c-1.172,1.171-1.172,3.071,0,4.242C23.422,14.662,24.189,14.955,24.957,14.955z"/></svg>';

const svgIconDiscard = '<svg class="H_icon H_icon" viewBox="0 0 32 32">' +
    '<path d="M26.5,8C25.675,8,25,7.1,25,6c0-1.1-0.9-2-2-2H9C7.9,4,7,4.9,7,6c0,1.1-0.675,2-1.5,2S4,8.9,4,10' +
    'c0,1.1,0.675,2,1.5,2c0.38,0,0.921,0,1.5,0v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V12c0.579,0,1.12,0,1.5,0c0.825,0,1.5-0.9,1.5-2' +
    'C28,8.9,27.325,8,26.5,8z M9,26V12h3v14H9z M14,26V12h4v14H14z M23,26h-3V12h3V26z M6,10c0-0.014,0-0.027,0.001-0.039' +
    'C7.718,9.691,9,8.048,9,6h14c0,2.048,1.282,3.691,2.999,3.961C26,9.973,26,9.986,26,10H6z"/></svg>';

const svgIconCheck = '<svg class="H_icon H_icon" viewBox="2 2 12 12">' +
    '<path d="M13.7071,4.29289 C14.0976,4.68342 14.0976,5.31658 13.7071,5.70711 ' +
    'L7.70711,11.7071 C7.31658,12.0976 6.68342,12.0976 6.29289,11.7071 L3.29289,8.70711 C2.90237,8.31658 2.90237,7.68342 ' +
    '3.29289,7.29289 C3.68342,6.90237 4.31658,6.90237 4.70711,7.29289 L7,9.58579 L12.2929,4.29289 C12.6834,3.90237 ' +
    '13.3166,3.90237 13.7071,4.29289 Z"/></svg>';

// const svgIconCheck2 = '<svg class="H_icon H_icon" viewBox="0 0 405.272 405.272">' +
//     '<path d="M393.401,124.425L179.603,338.208c-15.832,15.835-41.514,15.835-57.361,0L11.878,227.836' +
//     'c-15.838-15.835-15.838-41.52,0-57.358c15.841-15.841,41.521-15.841,57.355-0.006l81.698,81.699L336.037,67.064' +
//     'c15.841-15.841,41.523-15.829,57.358,0C409.23,82.902,409.23,108.578,393.401,124.425z"/></svg>';

const svgIconCancel = '<svg class="H_icon H_icon" viewBox="0 0 42 42">' +
    '<path d="M21.002,26.588l10.357,10.604c1.039,1.072,1.715,1.083,2.773,0l2.078-2.128' +
	'c1.018-1.042,1.087-1.726,0-2.839L25.245,21L36.211,9.775c1.027-1.055,1.047-1.767,0-2.84l-2.078-2.127' +
	'c-1.078-1.104-1.744-1.053-2.773,0L21.002,15.412L10.645,4.809c-1.029-1.053-1.695-1.104-2.773,0L5.794,6.936' +
	'c-1.048,1.073-1.029,1.785,0,2.84L16.759,21L5.794,32.225c-1.087,1.113-1.029,1.797,0,2.839l2.077,2.128' +
	'c1.049,1.083,1.725,1.072,2.773,0L21.002,26.588z"/></svg>';

// const svgIconCancel1 = '<svg class="H_icon H_icon" viewBox="2 2 20 20">' +
//     '<path d="M12 4c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm-5 8c0-.832.224-1.604.584-2.295l6.711 6.711' +
//     'c-.691.36-1.463.584-2.295.584-2.757 0-5-2.243-5-5zm9.416 2.295l-6.711-6.711c.691-.36 1.463-.584 2.295-.584 2.757 0 5 ' +
//     '2.243 5 5 0 .832-.224 1.604-.584 2.295z"/></svg>';

// const svgIconCancel2 = '<svg class="H_icon H_icon" viewBox="-5 -5 57.095 57.095">' +
//     '<path d="M45.363,36.234l-13.158-13.16l12.21-12.21c2.31-2.307,2.31-6.049,0-8.358c-2.308-2.308-6.05-2.307-8.356,0l-12.212,12.21' +
//     'L11.038,1.906c-2.309-2.308-6.051-2.308-8.358,0c-2.307,2.309-2.307,6.049,0,8.358l12.81,12.81L1.732,36.831' +
//     'c-2.309,2.31-2.309,6.05,0,8.359c2.308,2.307,6.049,2.307,8.356,0l13.759-13.758l13.16,13.16c2.308,2.308,6.049,2.308,8.356,0' +
//     'C47.673,42.282,47.672,38.54,45.363,36.234z"/></svg>';

const svgIconNavigate = '<svg class="H_icon H_icon" viewBox="64 0 448 448">' +
    '<path d="M272,464a16,16,0,0,1-16-16.42V264.13a8,8,0,0,0-8-8H64.41a16.31,16.31,0,0,1-15.49-10.65,16,16,0,0,1,8.41-19.87l384-176.15' +
    'a16,16,0,0,1,21.22,21.19l-176,384A16,16,0,0,1,272,464Z"/></svg>';

const svgIconInfo = '<svg class="H_icon H_icon" viewBox="0 0 245.334 245.334">' +
    '<path d="M122.667,0C55.028,0,0,55.028,0,122.667s55.027,122.667,122.666,122.667s122.667-55.028,122.667-122.667 ' +
    'S190.305,0,122.667,0z M122.667,215.334C71.57,215.334,30,173.764,30,122.667S71.57,30,122.667,30s92.667,41.57,92.667,92.667 ' +
    'S173.763,215.334,122.667,215.334z"/>' +
    '<rect width="30" x="107.667" y="109.167" height="79"/><rect width="30" x="107.667" y="57.167" height="29"/></svg>';

const svgIconFilter = '<svg class="H_icon H_icon" viewBox="1 0 22 22">' +
    '<path d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,' +
    '1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,' +
    '6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,' +
    '0,1,5,4H19a1,1,0,0,1,1,1Z"/></svg>';

const svgIconUndo = '<svg class="H_icon H_icon" viewBox="0 0 329.028 329.028">' +
    '<path d="M241.853,72.46L252.7,17.926c1.184-5.95-1.334-12.028-6.378-15.398c-5.045-3.37-11.623-3.37-16.668,0' +
    'L156.181,51.62c-0.005,0.004-0.01,0.007-0.015,0.011c-0.339,0.227-0.663,0.476-0.982,0.729c-0.065,0.052-0.137,0.098-0.201,0.15' +
    'c-0.375,0.309-0.734,0.635-1.077,0.978c-0.105,0.105-0.198,0.222-0.3,0.33c-0.231,0.246-0.465,0.49-0.68,0.751' +
    'c-0.086,0.104-0.159,0.217-0.242,0.324c-0.217,0.279-0.435,0.559-0.632,0.853c-0.05,0.074-0.091,0.153-0.139,0.228' +
    'c-0.218,0.337-0.429,0.677-0.618,1.031c-0.021,0.04-0.039,0.083-0.06,0.123c-0.207,0.393-0.4,0.794-0.571,1.206' +
    'c-0.009,0.02-0.015,0.042-0.023,0.062c-0.177,0.431-0.337,0.871-0.475,1.321c-0.012,0.041-0.02,0.084-0.031,0.125' +
    'c-0.127,0.428-0.239,0.86-0.327,1.303c-0.001,0.007-0.004,0.013-0.005,0.02c-0.027,0.137-0.038,0.276-0.062,0.414' +
    'c-0.058,0.339-0.117,0.678-0.151,1.025c-0.044,0.44-0.062,0.881-0.066,1.321c-0.001,0.056-0.009,0.11-0.009,0.167' +
    'c0,0.001,0,0.003,0,0.004c0,0.5,0.026,0.999,0.075,1.495c0.031,0.307,0.087,0.606,0.135,0.907c0.029,0.181,0.047,0.362,0.083,0.542' +
    'c0.077,0.387,0.18,0.765,0.286,1.142c0.025,0.091,0.043,0.184,0.07,0.274c0.121,0.398,0.266,0.786,0.418,1.17' +
    'c0.027,0.069,0.048,0.141,0.076,0.21c0.162,0.389,0.345,0.766,0.537,1.137c0.033,0.064,0.06,0.131,0.094,0.194' +
    'c0.206,0.384,0.432,0.755,0.67,1.118c0.029,0.046,0.054,0.095,0.084,0.141l0.013,0.02c0.014,0.02,0.026,0.04,0.04,0.059' +
    'l49.041,73.395c2.817,4.217,7.525,6.667,12.47,6.667c0.971,0,1.952-0.094,2.929-0.288c5.95-1.184,10.602-5.835,11.785-11.786' +
    'l7.052-35.455c23.901,20.188,39.109,50.36,39.109,84.022c0,60.636-49.331,109.968-109.967,109.968' +
    'c-60.637,0-109.969-49.332-109.969-109.968c0-29.373,11.44-56.988,32.21-77.759c5.858-5.858,5.858-15.355,0-21.213' +
    'c-5.857-5.858-15.356-5.858-21.213,0c-26.437,26.437-40.997,61.586-40.997,98.972c0,77.178,62.79,139.968,139.969,139.968' +
    'c77.178,0,139.967-62.79,139.967-139.968C304.482,140.453,279.572,97.561,241.853,72.46z"/></svg>';

// const svgIconThink = '<svg class="H_icon H_icon" viewBox="4 4 24 24">' +
//     '<path d="M16,4c-4.963,0-9,4.038-9,9c0,3.186,1.781,5.278,3.212,6.959C11.172,21.085,12,22.059,12,23v5h3' +
//     'v1h2v-1h3v-5c0-0.941,0.828-1.915,1.788-3.041C23.219,18.278,25,16.186,25,13C25,8.038,20.963,4,16,4z M18,26h-4v-2h4V26z' +
//     ' M20.265,18.662c-0.923,1.084-1.805,2.12-2.132,3.338h-4.266c-0.327-1.218-1.209-2.254-2.132-3.338C10.391,17.083,9,15.45,9,13' +
//     'c0-3.86,3.141-7,7-7s7,3.14,7,7C23,15.45,21.609,17.083,20.265,18.662z M16,7v2c-2.206,0-4,1.794-4,4h-2C10,9.691,12.691,7,16,7z"/>' +
//     '</svg>';

// const svgIconAnalyse = '<svg class="H_icon H_icon" viewBox="0 0 256 256" fill="none">' +
//     '<path d="M98.6,32.6c24,0,43.4,19.4,43.4,43.4s-19.4,43.6-43.4,43.6s-43.4-19.4-43.4-43.4S74.6,32.6,98.6,32.6z M221.8,215.6h-54.4' +
//     'l25.4-43.8c5.2-9.2,2-20.8-7-26s-20.6-2-25.8,7l-25.4,43.8l-37.8-64.8C93.2,125,86,121.6,77,121.6c-1.2,0-3.8,0.4-5.2,0.8' +
//     'c-1.2,0.2-3,0.8-4.4,1.2C31,135.6,2.2,193.8,2.2,254c1,0,87.4,0,109.4,0c-0.8-1.2-1.6-2.2-2.4-3.4L65.4,175c-1.4-2.6-0.4-6,2-7.4' +
//     'c2.6-1.4,6-0.4,7.4,2l43.4,75c3.2,5.6,9.2,9.4,16.2,9.4h87.4c10.6,0,19-8.6,19-19C241,224,232.2,215.6,221.8,215.6z M203.613,29.883' +
//     'v-6.156l-6.453-1.09c-0.477-2.109-1.309-4.086-2.43-5.863l3.793-5.332l-4.355-4.355l-5.332,3.793' +
//     'c-1.773-1.121-3.75-1.949-5.863-2.43L181.887,2h-6.16l-1.086,6.449c-2.113,0.48-4.09,1.309-5.863,2.43l-5.332-3.793l-4.355,4.355' +
//     'l3.793,5.332c-1.121,1.777-1.953,3.754-2.43,5.863L154,23.727v6.156l6.453,1.086c0.477,2.113,1.309,4.09,2.43,5.867l-3.793,5.332' +
//     'l4.355,4.352l5.332-3.793c1.773,1.121,3.75,1.953,5.863,2.43l1.086,6.453h6.16l1.086-6.453c2.113-0.477,4.09-1.309,5.863-2.43' +
//     'l5.332,3.793l4.355-4.352l-3.793-5.332c1.121-1.777,1.953-3.754,2.43-5.867L203.613,29.883z M178.805,36.594' +
//     'c-5.402,0-9.785-4.383-9.785-9.789s4.383-9.789,9.785-9.789c5.406,0,9.789,4.383,9.789,9.789S184.211,36.594,178.805,36.594z' +
//     ' M246.48,65.19l7.52-4.667l-3.154-7.707l-8.635,1.943c-1.629-2.327-3.659-4.397-6.043-6.096l2.017-8.616l-7.68-3.22l-4.73,7.479' +
//     'c-2.883-0.509-5.782-0.505-8.584-0.035l-4.668-7.52l-7.707,3.154l1.943,8.635c-2.327,1.629-4.397,3.659-6.096,6.043l-8.616-2.017' +
//     'l-3.22,7.68l7.479,4.73c-0.509,2.883-0.505,5.782-0.035,8.584l-7.52,4.668l3.154,7.707l8.635-1.943' +
//     'c1.629,2.327,3.659,4.397,6.043,6.096l-2.017,8.616l7.68,3.22l4.73-7.479c2.883,0.509,5.782,0.505,8.584,0.035l4.668,7.52' +
//     'l7.707-3.154l-1.943-8.635c2.327-1.629,4.397-3.659,6.096-6.043l8.616,2.017l3.22-7.68l-7.479-4.73' +
//     'C246.953,70.891,246.95,67.992,246.48,65.19z M233.584,74.493c-2.827,6.743-10.584,9.918-17.327,7.091' +
//     'c-6.743-2.827-9.918-10.584-7.091-17.327s10.584-9.918,17.327-7.091C233.236,59.993,236.41,67.75,233.584,74.493z"/>' +
//     '</svg>';


const svgIconOverlay = '<svg class="H_icon H_icon" viewBox="1 0 31 31">' +
    '<path d="M28,8H24V4a2.0023,2.0023,0,0,0-2-2H4A2.0023,2.0023,0,0,0,2,4V22a2.0023,2.0023,0,0,0,2,2H8v4a2.0023,2.0023,' +
    '0,0,0,2,2H28a2.0023,2.0023,0,0,0,2-2V10A2.0023,2.0023,0,0,0,28,8ZM4,22V4H22V8H10a2.0023,2.0023,0,0,0-2,2V22Zm18,' +
    '0H19.4141L10,12.586V10h2.5859l9.4153,9.4156ZM10,15.4141,16.5859,22H10ZM22.001,16.587,15.4141,10H22ZM10,28V24H22a2.0023,' +
    '2.0023,0,0,0,2-2V10h4V28Z"/></svg>';


export {
    svgIconEdit, svgIconImport, svgIconExport, svgIconDiscard, svgIconCheck,
    svgIconCancel, svgIconNavigate, svgIconInfo, svgIconFilter, svgIconUndo, svgIconOverlay
};
