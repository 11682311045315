/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAppConfiguration = /* GraphQL */ `
  query GetAppConfiguration($msg: String) {
    getAppConfiguration(msg: $msg)
  }
`;
export const getCustomerList = /* GraphQL */ `
  query GetCustomerList($msg: String) {
    getCustomerList(msg: $msg)
  }
`;
export const accessTmsApi = /* GraphQL */ `
  query AccessTmsApi($request: String) {
    accessTmsApi(request: $request)
  }
`;
