/**
 * TMS related functions
 * @author David Kirkland <david.kirkland@nec.com.au>
 */

import { accessTmsApiViaAws } from "./AwsFunctions";
import { Logger } from 'aws-amplify';

const logger = new Logger('TmsUtils', 'INFO');

/**
 * Get the list of available routes from TMS
 * @param {string} customer the customer name
 * @param {string} routeName optional route name to filter on
 * @param {string} destinationsToExclude optional route destinations to exclude
 * @param {*} callBack the function to call after retrieving the list of routes
 */
function getTmsRouteList(customer, routeName, destinationsToExclude, callBack) {
    let request = {
        method: "GET",
        resource: "route",
        customer: customer,
        return: { key: "routeList" }
    }
    if (routeName) {
        request["query"] = { "name": routeName };
    }
    if (destinationsToExclude) {
        let destinations = [];
        for (let dest of destinationsToExclude.split(",")) {
            destinations.push(dest.trim());
        }
        request["return"]["exclusions"] = { "routeDestination": destinations }
    }
    logger.info(request);
    accessTmsApiViaAws(JSON.stringify(request), callBack);
}

/**
 * Get the details of a route from TMS
 * @param {*} customer the customer name
 * @param {*} routeId the TMS route ID
 * @param {*} callBack the function to call after retrieving the list of routes
 */
function getTmsRouteDetails(customer, routeId, callBack) {
    let request = {
        method: "GET",
        resource: "route/" + routeId,
        customer: customer
    }
    accessTmsApiViaAws(JSON.stringify(request), callBack);
}

/**
 * Convert a TMS route object into the format that we require
 * @param {*} tmsRoute the TMS route details
 * @returns {*} the route object
 */
function convertTmsRoute(tmsRoute) {
    let geometry = [],
        stops = [],
        details = {};

    // Extract the route geometry
    if ('geometry' in tmsRoute) {
        // geometry should be in the form "LINESTRING (lon lat, lon lat, ...)"
        const pattern = /LINESTRING \((.*)\)/;
        const match = tmsRoute.geometry.match(pattern);
        if (match) {
            let linestring = match[1];
            const coordinates = linestring.split(",");
            for (const coord of coordinates) {
                const latLon = coord.trim().split(" ");
                if (latLon.length === 2) {
                    geometry.push({ lat: latLon[1], lng: latLon[0] });
                } else {
                    // error
                    logger.error("Invalid route geometry point: " + coord);
                    return null;
                }
            }
        } else {
            // error
            logger.error("Invalid route geometry");
            return null;
        }
    }

    // extract the list of stops
    if ('stopsOnRoute' in tmsRoute) {
        // geometry should be in the form "POINT (lon lat)"
        const pattern = /POINT \((.*) (.*)\)/;
        for (const stopOnRoute of tmsRoute.stopsOnRoute) {
            const match = stopOnRoute.geometry.match(pattern);
            let stop = {};
            if (match && match.length === 3) {
                stop.lat = match[2];
                stop.lng = match[1];
                // add stop properties
                stop.stopName = stopOnRoute.stopName;
                stop.stopCode = stopOnRoute.stopCode;
                stop.stopId = stopOnRoute.stopCode;     // save the stop code as the "id" also (used by the driver app)
                stop.sequenceNo = stopOnRoute.sequenceNumber;
                stop.tmsStopId = stopOnRoute.stopId;
                stops.push(stop);
            } else {
                // error
                logger.error("Invalid stop geometry");
                return null;
            }
        }
    }

    // save the route details
    details.route = tmsRoute.routeName;
    details.destination = tmsRoute.routeDestination;
    details.variant = tmsRoute.routeVariant;
    details.description = tmsRoute.routeAlternativeName;
    
    // create the route object
    let route = { geometry, stops, details };
    logger.debug(route);
    return route;
}

/**
 * Process a TMS data bundle file (usually used for importing data into TMS)
 * @param {*} bundle
 * @returns {*} the route object (in TMS format)
 */
function processTmsDataBundle(bundle) {
    if (!('dataItems' in bundle)) {
        return { error: "Invalid TMS data bundle file" };
    }

    let routes = bundle.dataItems['1'],
        stops = bundle.dataItems['3'];

    if (routes.length > 0) {
        routes.forEach((route) => {
            // save the route code as the key
            route.key = route.routeCode;
            // set some values so we can use the TMS route converter
            route.routeName = route.route;
            route.routeDestination = route.destination;
            route.routeVariant = route.variant;
            route.routeAlternativeName = route.destinationDescription;
            // set the stops
            route.stopsOnRoute = [];
            for (const s of route.stopOnRoutes) {
                let stopOnRoute = {};
                stopOnRoute.stopCode = s.stopcode;
                stopOnRoute.stopId = s.stopcode;        // save the stop code as the "id" also (used by the driver app)
                stopOnRoute.sequenceNumber = s.sequence;
                for (const stop of stops) {
                    if (s.stopcode === stop.stopcode) {
                        stopOnRoute.geometry = stop.position;
                        stopOnRoute.stopName = stop.name;
                        break;
                    }
                }
                if ('geometry' in stopOnRoute) {
                    route.stopsOnRoute.push(stopOnRoute);
                } else {
                    logger.warn("Stop details unavailable for stop code " + stopOnRoute.stopCode);
                }
            }
        })
    }
    return { routeList: routes };
}


export {
    getTmsRouteList, getTmsRouteDetails, convertTmsRoute, processTmsDataBundle
};
