/**
 * Route Import utility functions
 * @author David Kirkland <david.kirkland@nec.com.au>
 */

import H from "@here/maps-api-for-javascript";
import { Logger } from 'aws-amplify';

const logger = new Logger('ImportUtils', 'INFO');

/**
 * Import a location log in the following format (only the latitude & longtitude values are used):
 *   "TIMESTAMP","PROVIDER","LATITUDE","LONGITUDE","ACCURACY" ...
 * 
 * @param {*} fileName the name of the location log file 
 * @param {*} logContent the file contents
 * @returns 
 */
function importLocationLog(fileName, logContent) {
    let lines = logContent.split(/\r\n|\n|\r/),
        geometry = [],
        stops = [],
        details = {};

    if (lines.length > 0) {
        logger.info("Loaded file " + fileName + " with " + lines.length + " lines");
        let latIndex = 2,
            lngIndex = 3;
        for (let i = 0; i < lines.length; i++) {
            let line = lines[i];
            if (line.length === 0) {
                continue;
            }
            let fields = line.split(",");
            if (fields.length < 5) {
                logger.warn("Skipping short log line #" + (i + 1) + ": [" + line + "]");
                continue;
            }
            if (i === 0) {
                // first line - check if there is a header present, to determine if we are using the old or new log format
                if (fields[0] === '"TIMESTAMP"') {
                    // check the header: "TIMESTAMP","PROVIDER","LATITUDE","LONGITUDE","ACCURACY" ....
                    if (fields[2] === '"LONGITUDE"') {
                        // old log format
                        latIndex = 3;
                        lngIndex = 2;
                    }
                    logger.info("Found header - using " + (latIndex === 2 ? "new" : "old") + " log format");
                    continue;
                }
            }

            // Extract the latitude/longitude
            let lat = parseFloat(fields[latIndex]),
                lng = parseFloat(fields[lngIndex]);
            if (isNaN(lat) || isNaN(lng)) {
                logger.warn("Skipping invalid lat or lon value on line #" + (i + 1) + ": " + line);
            } else {
                if (geometry.length > 0) {
                    let thisPoint = new H.geo.Point(lat, lng),
                        prevPoint = new H.geo.Point(geometry[geometry.length - 1].lat, geometry[geometry.length - 1].lng);
                    if (prevPoint.distance(thisPoint) < 3) {
                        // avoid having a bunch of points on top of each other 
                        logger.debug("Skipping line #" + (i + 1) + ": too close to previous point");
                        continue;
                    }
                }
                geometry.push({ lat, lng });
            }
        }

        if (geometry.length >= 2) {
            // add stops at the start and end of the geometry
            stops.push({
                lat: geometry[0].lat,
                lng: geometry[0].lng,
                sequenceNo: 1,
                stopName: "Route Start"
            });
            stops.push({
                lat: geometry[geometry.length - 1].lat,
                lng: geometry[geometry.length - 1].lng,
                sequenceNo: 2,
                stopName: "Route End"
            });
        }
    }

    return { geometry, stops, details };
}


export {
    importLocationLog
};
